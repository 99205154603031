import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth';
import { MuiThemedCircularProgress } from './MuiThemedComponents';

const Loading = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();

    useEffect(() => {
        let tooltip = document.querySelector('.MuiTooltip-popper');
        if(tooltip){
            tooltip.style.display = 'none';
        }
    },[]);

    useEffect(() => {
        if (auth.isAuthorized) {
            const redirectEndPoint = localStorage.getItem('redirectEndPoint');
            if(redirectEndPoint){
                navigate(redirectEndPoint);
                localStorage.removeItem('redirectEndPoint');
            } else {
                location?.pathname === "/" ? navigate(`/nf-status/dashboard`) : navigate(`${location?.pathname}${location?.search}`,{state: location?.state});
            }
           
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthorized]);

    return (
        <div className="loader-container">
        <MuiThemedCircularProgress  /> 
        </div>
    );
};

export default Loading;
