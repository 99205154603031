// axiosInstance.js
import axios from 'axios';
import { toast } from 'react-toastify';
import PKCEAuth from "@star2star/cpaas-pkce-auth";

const instance = axios.create();

instance.interceptors.request.use(async (config) => {
    config.timeout = 60000; // Wait for 60 seconds before timing out
        const deviceId = localStorage.getItem(`${process.env.REACT_APP_OAUTH_CLIENT_ID}_device_id`);
        const token = await PKCEAuth.getAccessToken();
        const tokenId = await PKCEAuth.getIdToken();
        if (token && tokenId) {
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['x-token-id'] = tokenId;
            config.headers['x-device-id'] = deviceId;
        }
    return config;
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {

        // store.dispatch(setLoading(false));

        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            // Handle Timeout
            toast.error("Request timed out")

        } else if (error.response.status === 401) {
            // Handle 401 error here
            toast.error(error.response.data ? error.response.data : "Unauthorized: Redirecting to login page...")

        } else if (error.response.status === 400) {
            // Handle 400 error here
            if (Array.isArray(error.response.data.message)) {
                error.response.data.message.forEach(data => {
                    toast.error(data?.message);
                })
            } else {


                toast.error(error.response.data && error.response.data.message ? error.response.data.message : (typeof error.response.data === 'string' ? error.response.data : "Bad Request"))
            }
        } else if (error.response.status === 500) {
            // Handle 500 error here
            toast.error(error.response.data ? error.response.data : "The server encountered an error and couldn't complete your request.")
        } else if (error.response.status === 403) {
            // Handle 403 error here
            window.location.href = '/nf-status/access-denied';
        }
        //reject the Promise and propagate the error to the caller
        return Promise.reject(error);
    }
);

export default instance;
