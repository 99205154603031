import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";

function FormPopup({ open, size, children, className }) {
	const user = useSelector(state => state?.user)

	return (
		<Modal
			keepMounted
			open={open}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
		>
			<Box className={`modal ${size} ${user?.themePreference === 'dark' ? "form__modal__darkMode " :''} ${className}`}>{children}</Box>
		</Modal>
	);
}

export default FormPopup;
