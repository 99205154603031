import Box from "@mui/material/Box";
import ProgressLoading from "components/common/ProgressLoading";
import FormPopup from "components/common/modal/FormPopup";
import Header from "components/common/modal/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { useNavigate } from "react-router";
import { fetchSiteServiceCount, setDeviceDetails } from "store/devices";
import './sites.css';
import TooltipLabel, { StyledTooltip } from "components/common/TooltipLabel/TooltipLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLayout from "components/common/Button";
import { MuiTypography } from "components/common/MuiThemedComponents";
import { Popper, Typography } from "@mui/material";
import CopyTextCell from "components/common/CopyTextCell/CopyTextCell";
import { ClickAwayListener } from "@mui/material";

function ServicePopup({ handleClosePopup, open, rowData, callingFrom, renderPopper, anchorEl }) {
	const dispatch = useDispatch();
	const [siteServiceData, setSiteServiceData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const user = useSelector((state) => state.user);
	const navigate = useNavigate();

	const getLinkHistoryById = async () => {
		await setIsLoading(true);
		let res = await dispatch(fetchSiteServiceCount({id :rowData?.id, tenantId: user.tenant?.id}));
		if (res?.payload) {
			setSiteServiceData(formatData(res?.payload));
		}
		await setIsLoading(false);
	};

	const commonStatus = (upCount, downCount, degradedCount, maintCount, otherCount, service) =>{
		return(
			<div className="statusCounts">
						<div className="statusPair">
							<span>{upCount}</span>
							<FontAwesomeIcon icon="fa-solid fa-arrow-up" color="green" />
						</div>
						<div className="statusPair">
							<span>{downCount}</span>
							<FontAwesomeIcon icon="fa-solid fa-arrow-down" color="red" />
						</div>
						<div className="statusPair">
							<span>{degradedCount}</span>
							<FontAwesomeIcon icon="fa-solid fa-arrow-down" color="#ff7917" />
						</div>
						{user?.tenant?.name === 'Sangoma' && <div className="statusPair">
							<span>{maintCount}</span>
							<FontAwesomeIcon icon="fa-solid fa-arrow-down" color="#fbbd00" />
						</div>}
						{service == 'FORTINET' && <div className="statusPair">
							<span>{otherCount}</span>
							<FontAwesomeIcon icon="fa-solid fa-arrow-down" color="gray" />
						</div>}
					</div>
		)
	}

	const formatData = (dataList) => {
		let rows = [
			{
				name: "Physical Address",
				value: rowData?.physical_address ?
					<div className="site__flex">
						<TooltipLabel text={rowData?.physical_address} />
						<a rel="noreferrer" href={`https://www.google.com/maps?q=${rowData?.physical_address}}`} style={{textDecoration: 'none'}}
							target="_blank">
							<ButtonLayout text={`Map`} buttonStyleType={"filledMap"} icon={<FontAwesomeIcon icon="fa-solid fa-location-dot"  style={{marginRight: '4px'}} className="iconPadding" />} />
						</a>
					</div>
				: '--',
				isEnabled: !(callingFrom === 'link-monitor')
			},
			{
				name: "Shipping Address",
				value: rowData?.shipping_address ? <TooltipLabel text = {rowData?.shipping_address}/> : '--',
				isEnabled: !(callingFrom === 'link-monitor')
			},
			{
				name: "GPS Coordinates",
				value: rowData?.latitude && rowData?.longitude ? 
					<div className="site__flex">
						<TooltipLabel text={rowData?.latitude + ', ' + rowData?.longitude} />
						<a rel="noreferrer" href={`https://www.google.com/maps?q=${rowData?.latitude},${rowData?.longitude}`} target="_blank" style={{textDecoration: 'none'}}>
							<ButtonLayout text={`Map`} buttonStyleType={"filledMap"} icon={<FontAwesomeIcon icon="fa-solid fa-location-dot" style={{marginRight: '4px'}} className="iconPadding" />} />
						</a>
					</div> : '--',
					isEnabled: !(callingFrom === 'link-monitor')
			},
			{
				name: "SDWAN",
				value: <div className="site__flex site_details_flex" >
					<div onClick={() => dataList?.velo_cloud?.velo_cloud > 0 ? handleSelectRow('SDWAN', dataList) : {}} style={{
						cursor: dataList?.velo_cloud?.velo_cloud > 0 ?'pointer':'default',
						textDecoration: dataList?.velo_cloud?.velo_cloud > 0 ? '' : 'none',
						}} className="site__serviceName">
						{dataList?.velo_cloud?.velo_cloud + (dataList?.velo_cloud?.velo_cloud <= 1 ? ' Device' : ' Devices')}
					</div>
					<div>
						{dataList?.velo_cloud?.velo_cloud > 0 ?
							commonStatus(dataList?.velo_cloud?.velo_cloudStatusCount?.upCount, dataList?.velo_cloud?.velo_cloudStatusCount?.downCount, dataList?.velo_cloud?.velo_cloudStatusCount?.degradedCount, dataList?.velo_cloud?.velo_cloudStatusCount?.maintCount)
							: null

						}
					</div>
				</div>,
				isEnabled: true
			},
			{
				name: "WIFI",
				value: <div className="site__flex site_details_flex">
				<div onClick={() => dataList?.meraki?.meraki > 0 ? handleSelectRow('WIFI', dataList):{}} className="site__serviceName" style={{
					cursor: dataList?.meraki?.meraki > 0 ?'pointer':'default',
					textDecoration: dataList?.meraki?.meraki > 0 ?'':'none',
					}}>
					{dataList?.meraki?.meraki + (dataList?.meraki?.meraki <= 1 ? ' Device' : ' Devices')}
				</div>
				<div>
					{dataList?.meraki?.meraki > 0 ?
					commonStatus(dataList?.meraki?.merakiStatusCount?.upCount, dataList?.meraki?.merakiStatusCount?.downCount, dataList?.meraki?.merakiStatusCount?.degradedCount, dataList?.meraki?.merakiStatusCount?.maintCount)
						: null

					}
					</div>
				</div>,
				isEnabled: true
			},
			{
				name: "CIRCUITS",
				value:<div className="site__flex site_details_flex">
				<div onClick={() => dataList?.wug?.wug > 0 ?  handleSelectRow('CIRCUITS', dataList): {}} className="site__serviceName" style={{
					cursor: dataList?.wug?.wug > 0 ?'pointer':'default',
					textDecoration: dataList?.wug?.wug > 0 ?'':'none',
					}}>
					{dataList?.wug?.wug + (dataList?.wug?.wug <= 1 ? ' Device' : ' Devices')}
				</div>
				<div>
					{dataList?.wug?.wug > 0 ?
					commonStatus(dataList?.wug?.wugDataStatusCount?.upCount, dataList?.wug?.wugDataStatusCount?.downCount, dataList?.wug?.wugDataStatusCount?.degradedCount, dataList?.wug?.wugDataStatusCount?.maintCount)
						: null

					}
					</div>
					</div>,
				isEnabled: true
			},
			{
				name: "CRADLEPOINT",
				value: 
					<div className="site__flex site_details_flex">
					<div onClick={() => dataList?.cradlepoint?.cradlepoint > 0 ? handleSelectRow('CRADLEPOINT', dataList) : {}} className="site__serviceName"  style={{
						cursor: dataList?.cradlepoint?.cradlepoint > 0 ?'pointer':'default',
						textDecoration: dataList?.cradlepoint?.cradlepoint > 0 ? '' : 'none',
						}}>
						{dataList?.cradlepoint?.cradlepoint + (dataList?.cradlepoint?.cradlepoint <= 1 ? ' Device' : ' Devices')}
					</div>
					<div>
					{dataList?.cradlepoint?.cradlepoint > 0 ?
					commonStatus(dataList?.cradlepoint?.cradlepointStatusCount?.upCount, dataList?.cradlepoint?.cradlepointStatusCount?.downCount, dataList?.cradlepoint?.cradlepointStatusCount?.degradedCount, dataList?.cradlepoint?.cradlepointStatusCount?.maintCount)
						: null

					}
					</div>
				
					</div>,
				isEnabled: true
			},
			{
				name: "FORTINET",
				value: <div className="site__flex site_details_flex_others">
					<div onClick={() =>dataList?.fortinet?.fortinet > 0 ? handleSelectRow('FORTINET', dataList): {}} className="site__serviceName" style={{
						cursor: dataList?.fortinet?.fortinet > 0 ?'pointer':'default',
						textDecoration: dataList?.fortinet?.fortinet > 0 ? '' : 'none',
						}}>
						{dataList?.fortinet?.fortinet + (dataList?.fortinet?.fortinet <= 1 ? ' Device' : ' Devices')}
					</div>
					<div>
					{dataList?.fortinet?.fortinet > 0 ?
					commonStatus(dataList?.fortinet?.fortinetStatusCount?.upCount, dataList?.fortinet?.fortinetStatusCount?.downCount, dataList?.fortinet?.fortinetStatusCount?.degradedCount, dataList?.fortinet?.fortinetStatusCount?.maintCount, dataList?.fortinet?.fortinetStatusCount?.otherCount, 'FORTINET')
						: null
					}
					</div>
				</div>,
				isEnabled: true
			},
			{
				name: "SWITCH",
				value: <div className="site_details_flex">
					<div onClick={() =>dataList?.switch?.switchCount > 0 ? handleSelectRow('SWITCH', dataList): {}} className="site__serviceName" style={{
						cursor: dataList?.switch?.switchCount  > 0 ?'pointer':'default',
						textDecoration: dataList?.switch?.switchCount  > 0 ? '' : 'none',
						}}>
						{dataList?.switch?.switchCount + (dataList?.switch?.switchCount <= 1 ? ' Device' : ' Devices')}
					</div>
				</div>,
				isEnabled: true
			}
		];
		return rows;
	};

	const handleSelectRow = (service_name, list) => {
		let filterdata = []
		let headerData = {};
		if (service_name == "SDWAN") {
			filterdata = list?.velo_cloud?.device;
			headerData = {up: list?.velo_cloud?.velo_cloudStatusCount?.upCount, down: list?.velo_cloud?.velo_cloudStatusCount?.downCount, 
				degraded: list?.velo_cloud?.velo_cloudStatusCount?.degradedCount}
		} else if (service_name == "WIFI") {
			filterdata = list?.meraki?.device;
			headerData = {up: list?.meraki?.merakiStatusCount?.upCount, down: list?.meraki?.merakiStatusCount?.downCount, 
				degraded: list?.meraki?.merakiStatusCount?.degradedCount}
		} else if (service_name == "CIRCUITS") {
			filterdata = list?.wug?.device;
			headerData = {up: list?.wug?.wugDataStatusCount?.upCount, down: list?.wug?.wugDataStatusCount?.downCount, 
				degraded: list?.wug?.wugDataStatusCount?.degradedCount}
		} else if (service_name == "CRADLEPOINT") {
			filterdata = list?.cradlepoint?.device;
			headerData = {up: list?.cradlepoint?.cradlepointStatusCount?.upCount, down: list?.cradlepoint?.cradlepointStatusCount?.downCount, 
				degraded: list?.cradlepoint?.cradlepointStatusCount?.degradedCount}
		} else if (service_name == "FORTINET") {
			filterdata = list?.fortinet?.device;
			headerData = {up: list?.fortinet?.fortinetStatusCount?.upCount, down: list?.fortinet?.fortinetStatusCount?.downCount, 
				degraded: list?.fortinet?.fortinetStatusCount?.degradedCount}
		} else if( service_name == "SWITCH"){
			filterdata = list?.switch?.device;
			headerData = {up: list?.switch?.switchStatusCount?.upCount, down: list?.switch?.switchStatusCount?.downCount, 
				degraded: list?.switch?.switchStatusCount?.degradedCount}
		}
		const serviceNameMappings = {
			SDWAN:'velo_cloud',
			WIFI:'meraki',
			CIRCUITS:'wug',
			CRADLEPOINT:'cradlepoint',
			FORTINET:'fortinet',
			SWITCH:'switch',
		}
		
		navigate('/nf-status/inventory', { state: { 
			deviceData: filterdata, headerData: headerData, 
			site_name: rowData?.site_display_name ? rowData?.site_display_name : rowData?.name,
			siteId: rowData?.id,
			serviceName: serviceNameMappings[service_name],
		} });
		dispatch(setDeviceDetails({ deviceData: filterdata }))
		handleClosePopup();
	}

	useEffect(() => {
		getLinkHistoryById();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowData?.id]);

	useEffect(() => {
        const tableWrap = document.querySelector('.rdt_table_wrap');

        const handleScroll = () => {
            if (open && renderPopper) {
                handleClosePopup();
            }
        };

        if (tableWrap) {
            tableWrap.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableWrap) {
                tableWrap.removeEventListener('scroll', handleScroll);
            }
        };
    }, [open, handleClosePopup]);

	const servicePopupContent = () =>{
		return (
			<>
			<Header text={"Site Details"} close={handleClosePopup} /><Box className={'formWrapper'}>
				<Box className={'justify-between'}>
					<Box className="flex-start link_heading">
						<MuiTypography className="head">Site Display Name : </MuiTypography>
						<StyledTooltip placement="bottom" title={`Site Name: ${rowData?.name}`}>
							<Typography className="site__name__heading">
								<CopyTextCell text={rowData?.site_display_name ? rowData?.site_display_name : rowData?.name} />
							</Typography>
						</StyledTooltip>
					</Box>

					{!(callingFrom === 'link-monitor') &&
						<ButtonLayout handleClick={() => navigate('/nf-status/link-monitoring', {
							state: { callingFrom: 'sitePopup', siteId: rowData?.id, tenantId: rowData?.tenant?.id }
						})} text={`Check Status`} buttonStyleType={"filledMap"} icon={<FontAwesomeIcon icon="fa-solid fa-table-list" className="siteIconPadding" />} />}
				</Box>

				<div className="site__table__wrap">
					<table className="site__table">
						<tbody>
							{isLoading && <ProgressLoading />}
							{!isLoading && siteServiceData?.map((i, key) => {
								if (i?.isEnabled) {
									return (<tr className="site__service__row" key={key}>
										<td className="site__service__td" style={{ width: '40%' }}>{i.name}</td>
										<td className="site__service__td" style={{ width: '60%', cursor: i.value === 0 ? 'default' : 'pointer' }}>{i.value}</td>
									</tr>);
								}

							})}
						</tbody>
					</table>
				</div>
			</Box></>
		)
	}

	return (
		renderPopper ? <Popper
			key={new Date()}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClosePopup}
			onClick={handleClosePopup}
			keepMounted={true}		
			PaperProps={{
				elevation: 0,			
			}}	
			placement="right-start"
			disablePortal={false}
			modifiers={{
				flip: {
					enabled: false
				},
				preventOverflow: {
					enabled: true,
					boundariesElement: "scrollParent"
				}
			}}
		>
			<ClickAwayListener onClickAway={() => {
				if (open) {
					handleClosePopup();
				}
			}}>
			<Box sx={{ minWidth: '500px', minHeight: '300px' , transform: 'none', position:'initial'}} 
			className={`modal  ${user?.themePreference === 'dark' ? "form__modal__darkMode " : ''} `}>
				{servicePopupContent()}			
			</Box>
			</ClickAwayListener>
		</Popper> :
		<FormPopup open={open} size={"modal_size"} className={"link_wrapper site__details__popup "} >
			{servicePopupContent()}
		</FormPopup>
	);
}

export default ServicePopup;
