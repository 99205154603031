import { useSelector } from 'react-redux';
import AccessControl from 'accesscontrol';

export function useCanAccess() {
    const user = useSelector((state) => state.user);

    // Function to check permissions
    const canAccess = (permissionType, module) => {
        try {
            // Ensure user and acl exist
            if (!user?.acl) {
                throw new Error('User ACL is not available');
            }

            const decodedPermissions = atob(user.acl);
            const userPermissions = JSON.parse(decodedPermissions);

            // Initialize AccessControl with parsed permissions
            const ac = new AccessControl(userPermissions);
            // Define a map of permission types
            const permissionMap = {
                'read': 'readAny',
                'update': 'updateAny',
                'delete': 'deleteAny',
                'create': 'createAny',
            };

            // Check if the user has the required permission
            const requiredPermission = ac.can(user?.role)[permissionMap[permissionType]](module).granted;

            return requiredPermission;
        } catch (error) {
            console.log('Error initializing ACL, Error:', error);
            return false;
        }
    };

    return canAccess;
}
