import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TooltipLabel from "./TooltipLabel/TooltipLabel";
import { useNavigate } from "react-router";

const Breadcrumbs = ({ text, secondaryText, primaryTextPath, style, filterValue, onRemoveFilter}) => {
	const user = useSelector((state) => state.user);
	const navigate = useNavigate();


	const onRemoveFilterClick = async() => {
		navigate(location.pathname, {state: null});
		onRemoveFilter();
	}
	return (
		<div className={user?.themePreference === 'dark' ?"breadcrumb darkMode": "breadcrumb"} style={{...style}}>
			<Link to={secondaryText ? `/nf-status/${primaryTextPath}` : ""}>{text}</Link>
			{secondaryText ?
				<span className="secondary" key={0}>
					&nbsp;
					<FontAwesomeIcon style={{ height: "10px" }} icon="fa-solid fa-chevron-right" />
					<div >{secondaryText}</div>
				</span> : null}

			{filterValue && <div className="redirection-filter" onClick={onRemoveFilterClick}>
				<TooltipLabel text = {filterValue} fontSize={'12px'}></TooltipLabel>
				<FontAwesomeIcon icon="fa-solid fa-xmark" style={{margin: '0px 0px 0px 5px'}}/>
			</div>}
		</div>
	);
};

export default Breadcrumbs;
