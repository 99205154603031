import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

// ** Fetch changelogs data
export const fetchMappingData = createAsyncThunk(
    "binding/mappedBinding",
    async (data) => {
        const url = `${base_url}/mapping-sites/all-mapsite-data`
        const response = await axios.get(url, {params: {...data}});
        return response.data;
    }
);


export const fetchUnMappedData = createAsyncThunk(
    "binding/unmappedBinding",
    async (data) => {
        const { id, ...rest } = data;
        if (id !== '') {
            const url = `${base_url}/services/get_unmapped_sites/${id}`
            const response = await axios.get(url, { params: { ...rest } });
            return response.data;
        } else {
            const response = await axios.get(`${base_url}/services/get_all_unmapped_sites`);
            return response.data;
        }
    }
)
