import React from 'react'
import TabsComponet from 'components/common/TabsComponet/TabsComponet';
import MappedBinding from './MappedBinding';
import UnmappedBinding from './UnmappedBinding';

export default function NewBinding() {
    const tabOptions = [
        {id : 'mapped', name: 'Mapped devices', component: <MappedBinding/>, value: 'mapped'},
        {id : 'Unapped', name: 'Unmapped devices', component: <UnmappedBinding/>, value: 'unMapped'}
    ]
  return (
    <TabsComponet tabs={tabOptions}/>
  )
}
