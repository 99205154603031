import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, Popper } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

function ActionMenu({
	openMenuList,
	open,
	handleActionMenuClose,
	openEditForm,
	editData,
	navURL,
	canEdit,
	canDelete,
	type,
	primaryText,
	handleDelete,
	canChnageLog = true,
	contact = false,
	navURL2,
	menu,
}) {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const handleChangeLog = () => {
		handleActionMenuClose()
		setTimeout(() => {
			navigate(navURL, {
				state: [{
					id: editData?.id,
					name: editData?.name || editData?.custom_fields?.circuit_name,
					site_display_name: editData?.custom_fields?.display_name,
					type: type,
					primaryText: primaryText,
				}],
			});
		}, 100);

	};

	const handleContactLog = () => {
		handleActionMenuClose();
		setTimeout(() => {
			navigate(navURL2, {
				state: [{
					id: editData?.id
				}],
			});
		}, 100);
	};

	useEffect(() => {
        const tableWrap = document.querySelector('.rdt_table_wrap');

        const handleScroll = () => {
            if (open) {
                handleActionMenuClose();
            }
        };

        if (tableWrap) {
            tableWrap.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableWrap) {
                tableWrap.removeEventListener('scroll', handleScroll);
            }
        };
    }, [open, handleActionMenuClose]);

	return (
		<Popper
			key={new Date()}
			anchorEl={openMenuList}
			open={open}
			onClose={handleActionMenuClose}
			onClick={handleActionMenuClose}
			className={user?.themePreference === 'dark' ? "menu_wrap darkMode" : " menu_wrap"}
			PaperProps={{
				elevation: 0,
			}}
			transformOrigin={{ horizontal: "center", vertical: "top" }}
			anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
		>
			<ClickAwayListener onClickAway={() => {
				if (open) {
					handleActionMenuClose();
				}
			}}>
				<div style={{ padding: "6px" }}>
					{canEdit && (
						<MenuItem className="list_item" onClick={() => openEditForm()}>
							<FontAwesomeIcon className="icon" icon="fa-solid fa-pen-to-square" />
							<span>Edit</span>
						</MenuItem>
					)}
					{canChnageLog && (
						<MenuItem className="list_item" onClick={handleChangeLog}>
							<FontAwesomeIcon
								className="icon"
								icon="fa-solid fa-clock-rotate-left"
							/>
							<span>Changelog</span>
						</MenuItem>
					)}

					{contact && (
						<MenuItem className="list_item" onClick={handleContactLog}>
							<FontAwesomeIcon
								className="icon"
								icon="fa-solid fa-user"
							/>
							<span>Contact</span>
						</MenuItem>
					)}
					{canDelete &&
						<MenuItem className="list_item" onClick={handleDelete}>
							<FontAwesomeIcon
								className="icon"
								icon="fa-solid fa-trash-can"
							/>
							<span>Delete</span>
						</MenuItem>
					}
					{menu?.map((item, idx) => {
						return (<MenuItem key={idx} className="list_item" onClick={() => item?.onClick(editData)}>
							{item?.menu}
						</MenuItem>)
					})}
				</div>
			</ClickAwayListener>
		</Popper>
	);
}

export default ActionMenu;
