import FormGroup from "@mui/material/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ThemedReactSelect } from "./MuiThemedComponents";
import InputGroup from "./InputGroupWithLabel";
import ButtonLayout from "./Button";
import MultiSelectDropDown from "./MultiSelectDropDown";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const FormLayout = ({ cloudSitetoMap, parameters, formValues, handleInputChange, handleInputChangeSide, handleSelectChange, handleSelectStatus, handleOnfocus, tabName, handleCheckboxChange, handleClick, handleClickButton, menuPlace, isSiteNamesLoading, markError,
	nameErrorMsg, isError }) => {

	const [value, setValue] = useState("");
	const tenants = useSelector((state) => state.devices?.tenants);

	const renderFormField = () => {
		return parameters?.map((params, i) => {
			const {
				parameterType,
				identifier,
				title,
				titleSide,
				identifierSide,
				widthMd,
				widthsm,
				widthxs,
				validationRules,
				defaultValue,
				placeholder,
				maxChar,
				hideFilterIcon, isDisabled, ...props
			} = params;
			let optionList =  Array.isArray(params?.optionList) ? params?.optionList : [];
			let inputValue = formValues?.[identifier] != undefined ? formValues?.[identifier] : defaultValue;
			let inputValueSide = formValues?.[identifierSide] != undefined ? formValues?.[identifierSide] : defaultValue;

			const isRequired = validationRules[0] === "required";
			if (Object?.keys(formValues)?.length) {
				if (identifier === "time_zone") {
					inputValue = { value: inputValue, label: inputValue }
				}
				if (identifier === "tenant") {
					if (typeof formValues?.[identifier] === "object") {
						inputValue = { value: inputValue?.id, label: inputValue?.name, object: inputValue }
					} else if (typeof formValues?.[identifier] === "number") {
						
						let filterdata = tenants?.filter(data => data?.id == formValues?.[identifier]);
						inputValue = value ? value: {value: filterdata[0]?.id, label: filterdata[0]?.name, object: filterdata[0]}
					}
				}
			}

			if (cloudSitetoMap && identifier === "remote_site") {
				inputValue = cloudSitetoMap


			}

			const getHelperMsg = (errorMsg) =>{
					if(errorMsg['id'] == identifier){
						return errorMsg['msg'];
					}
			}
			
			switch (parameterType) {
				case "InputField":
					return (
						<Grid
							key={i}
							item
							xs={widthxs}
							sm={widthsm}
							md={widthMd}
						>
							<InputGroup
								label={identifier === "remote_site" ? tabName : title}
								id={identifier}
								value={inputValue}
								disable={identifier === "remote_site" ? true : false || props.isDisable}
								classname={`input_field ${isRequired && inputValue?.length === 0 && markError? ' input__required' : ''}`}
								isRequired={isRequired}
								setValues={handleInputChange}
								handleOnBlur={handleOnfocus}
								placeholder={`Enter ${identifier}`}
								// characterCounter="Maximun characters: 40"
								maxChar= {maxChar}
								helperText={getHelperMsg(nameErrorMsg)}
								error={isError}
							/>
						</Grid>
					);
				case "Status_ListSingle":
					return (
						<Grid
							key={i}
							item
							className="input_wrap"
							xs={widthxs}
							sm={widthsm}
							md={widthMd}
						>
							<FormGroup className="form_group_wrap">
								<InputLabel className="input_label">
									{title}
									{isRequired ? <span className="astrick">*</span> : null}
								</InputLabel>
								<ThemedReactSelect
									isRequired={isRequired && (inputValue?.length === 0 || inputValue == null || inputValue === "" || !inputValue) && markError}

									isClearable
									name={identifier}
									onChange={(newValue) => {
										handleSelectStatus(newValue);
									}}
									options={optionList?.map((option) => {
										return {
											value: option.value,
											label: option.label,
										}
									})}
									placeholder={identifier == 'state' ? "Select State" : "Select Status"}
									value={inputValue}
									className="react-select-container"
									menuPlacement={menuPlace}
								/>
							</FormGroup>
						</Grid>
					);
					case "MultiSelect":
						return (
							<Grid
								key={i}
								item
								className="input_wrap"
								xs={widthxs}
								sm={widthsm}
								md={widthMd}
							>
								<FormGroup className="form_group_wrap">
									<InputLabel className="input_label">
										{title}
										{isRequired ? <span className="astrick">*</span> : null}
									</InputLabel>

									<MultiSelectDropDown controlShouldRenderValue = {false}
									hideFilterIcon = {hideFilterIcon} isLoading = {props?.isLoading}
						onChangeValue={(newValue) => {
							handleSelectChange(newValue, identifier);
						}}
						value={inputValue}
						options={optionList}
						placeholder={placeholder}
						menuPlacement={menuPlace}							
						isClearable
						name={identifier}/>
								</FormGroup>
							</Grid>
						);
				case "ListSingle":
					return (
						<Grid
							key={i}
							item
							className="input_wrap"
							xs={widthxs}
							sm={widthsm}
							md={widthMd}
						>
							<FormGroup className="form_group_wrap">
								<InputLabel className="input_label">
									{title}
									{isRequired ? <span className="astrick">*</span> : null}
								</InputLabel>
								<ThemedReactSelect
									isRequired={isRequired && (inputValue?.length === 0 || inputValue == null || inputValue === "" || !inputValue) && markError}
									isClearable
									name={identifier}
									onChange={(newValue) => {
										handleSelectChange(newValue, identifier);
									}}
									options={optionList?.map((option) => {
										return {
											value: option,
											label: option,
										}
									})}
									placeholder="Search..."
									value={inputValue}
									className="react-select-container"
								/>
							</FormGroup>
						</Grid>
					);
				case "Textarea":
					return (
						<Grid
							key={i}
							item
							className="input_wrap"
							xs={widthxs}
							sm={widthsm}
							md={widthMd}
						>
							<FormGroup className="form_group_wrap">
								<InputLabel className="input_label">
									{title}
									{isRequired ? <span className="astrick">*</span> : null}
								</InputLabel>
								<textarea						
									rows={3}
									className="input_field textarea"								
									onChange={(e) => handleInputChange(e, identifier)}
									value={inputValue}
									maxLength={maxChar}
									placeholder={placeholder}									
								/>
							</FormGroup>
						</Grid>
					);
				case "Autocomplete":
					return (
						<Grid
							key={i}
							item
							className="input_wrap"
							xs={widthxs}
							sm={widthsm}
							md={widthMd}
						>
							<FormGroup className="form_group_wrap">
								<InputLabel className="input_label">
									{title}
									{isRequired ? <span className="astrick">*</span> : null}
									{identifier === "site_name" && isSiteNamesLoading ? <CircularProgress /> : ""}
								</InputLabel>
								
								<ThemedReactSelect
									isClearable
									name={identifier}
									isRequired={isRequired && (inputValue?.length === 0 || inputValue == null || inputValue === "" || !inputValue) && markError }
									onChange={(newValue) => {
										handleSelectChange(newValue, identifier);
										identifier === "tenant"?setValue(newValue):null;
									}}
									options={optionList?.map((option) => {
										return {
											value: option.id,
											label: identifier === "site_name"? option?.custom_fields?.display_name ? 
											option?.custom_fields?.display_name: option.name: option.name,
											object: option
										}
									})}
									placeholder="Search..."
									maxMenuHeight={220}
									menuPlacement={"auto"}
									value={inputValue}
									className="react-select-container"
									isDisabled={identifier === "site_name" ? isSiteNamesLoading : isDisabled }
									
								/>
							</FormGroup>
						</Grid>
					);
				case "lineDropDowns":
					return (
						<Grid
							container
							key={i}
							item
							className="input_wrap"
							xs={12}
							direction="row"
							spacing={2}
							alignItems="center"
						>
							<Grid item xs={6}>
								<FormGroup className="form_group_wrap flex">
									<InputLabel className="input_label" style={{ alignSelf: "flex-start" }}>
										{title}
										{isRequired ? <span className="astrick">*</span> : null}
									</InputLabel>
									<ThemedReactSelect

										isRequired={isRequired && (inputValue?.length === 0 || inputValue == null || inputValue === "" || !inputValue) && markError}
										isClearable
										name={identifier}
										onChange={(newValue) => {
											handleSelectChange(newValue, identifier);
										}}
										options={optionList?.map((option) => {
											return {
												value: option.id,
												label: option.model,
												object: option
											};
										})}
										placeholder="Search..."
										maxMenuHeight={220}
										menuPlacement="auto"
										value={inputValue}
										className="react-select-container"
										isLoading={props?.isLoading}
										isDisabled={identifier === "device_type" ? isDisabled : false}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={5} sm={7} md={5} style={{ display: 'flex', marginTop: '15px' }}>
								<Grid container spacing={2}>
									<Grid item>
										<ButtonLayout
											text={`Device Type`}
											buttonStyleType="outlined"
											icon={<FontAwesomeIcon icon="fa-solid fa-plus" className="iconPadding" />}
											Iconstyle={{ marginRight: '8px' }}
											handleClick={() => handleClick(identifier)}
										/>
									</Grid>
									{props.isCircuitsEnable ?
										<Grid item>
											<ButtonLayout
												text={`Associate circuits`}
												buttonStyleType="outlined"
												icon={<FontAwesomeIcon icon="fa-solid fa-plus" className="iconPadding" />}
												Iconstyle={{ marginRight: '8px' }}
												handleClick={() => handleClickButton(identifier)}
											/>
										</Grid> : null
									}
								</Grid>
							</Grid>
						</Grid>
					);
				case "button":
					return (<Grid item xs={widthxs} sm={widthsm} md={widthMd} style={{ display: 'flex', marginTop: '20px' }}>
						<FormGroup className="form_group_wrap flex" >
							<ButtonLayout
								text={title}
								buttonStyleType="outlined"
								handleClick={(e) => handleClick(identifier, e)}
							/>
						</FormGroup>
					</Grid>)
				case "lineInputs":
					return (
						<Grid
							container
							key={i}
							item
							className="input_wrap"
							xs={12} 
							direction="row" 
							spacing={2} 
						>
							<Grid item xs={6}> 
								<FormGroup className="form_group_wrap" style={{ alignItems: "flex-start"}} >
									
									<InputGroup
										label={title}
										id={identifier}
										value={inputValue}
										disable={false}
										classname={`input_field `}
										setValues={handleInputChange}
										handleOnBlur={handleOnfocus}
										placeholder={`Enter ${title}`}
										styles={{width:'100%'}}
										maxChar={maxChar}
										helperText={getHelperMsg(nameErrorMsg)}
										error={isError}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup className="form_group_wrap flex" style={{ alignItems: "flex-start" }}>
									<InputGroup
										label={titleSide}
										id={identifierSide}
										value={inputValueSide}
										disable={false}
										classname={`input_field ${isRequired && inputValueSide?.length === 0 && markError? ' input__required' : ''}`}
										isRequired={isRequired}
										setValues={handleInputChangeSide}
										handleOnBlur={handleOnfocus}
										placeholder={`Enter ${titleSide}`}
										styles={{width:'100%'}}
										maxChar={maxChar}
									/>
								</FormGroup>
							</Grid>
						</Grid>
					);
					

				case "Checkbox":
					return (
						<Grid
							key={i}
							item
							className="input_wrap"
							style={{ paddingTop: "0px" }}
							xs={widthxs}
							sm={widthsm}
							md={widthMd}
						>
							<FormGroup className="form_group_wrap">
								<FormControlLabel
									onChange={(e) => handleCheckboxChange(e, identifier)}
									control={<Checkbox defaultChecked={inputValue ? true : false} />}
									label={title}
								/>
							</FormGroup>
						</Grid>
					);
				case "Label":
					return (
						<Grid
							key={i}
							item
							xs={widthxs}
							sm={widthsm}
							md={widthMd}
						>
							<InputLabel
								style={{ marginBottom: "0px", marginTop: "5px" }}
								className="input_label">
								{title}
							</InputLabel>
						</Grid>
					);
				default:
					return null;
			}
		});
	};

	return (
		<Grid container spacing={"12px"}>
			{renderFormField()}
		</Grid>
	);
};

export default FormLayout;
