import React, { Suspense, useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { TableTypes } from 'constant/TableColTypes';
import TableComponent from 'components/common/TableComponent/TableComponent';
import { useDispatch } from 'react-redux';
import { deleteContactData, fetchContactDataByTenantId } from 'store/devices';
import ButtonLayout from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormPopup from 'components/common/modal/FormPopup';
import ActionMenu from 'components/common/ActionMenu';
import { toast } from 'react-toastify';
import DeleteConfirmPopup from 'components/component/userManagement/DeleteConfirmPopup';
import CreateContact from 'components/component/createTenants/createContact';
import { useLocation } from 'react-router';
import Breadcrumbs from 'components/common/Breadcrumps';
import { MuiThemedBox } from 'components/common/MuiThemedComponents';
import SearchInputField from 'components/common/SearchInputField';
import ActionComponent from 'components/common/ActionComponent';
import { useCanAccess } from 'services/canACLAccess';

const ContactDetails = () => {
    const [tableColumns, setTableColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [openActionMenu, setopenActionMenu] = useState(null);
    const open = Boolean(openActionMenu);
    const [editData, seteditData] = useState();
    const [openDeleteConfimation, setOpenDeleteConfimation] = useState(false)
    const [paginationState, setPaginationState] = useState({
        offset: 1, limit: 20
    });
    const [searchTextState, setSearchTextState] = useState(null);
    const [openContact, setOpenContact] = useState(false);
    const location = useLocation();
    const initData = location?.state?.[0];
	const { id } = initData || {};
	const canAccess = useCanAccess();


    const dispatch = useDispatch();

    const setPaginationData = (newState) => {
        setPaginationState(prevState => ({ ...prevState, ...newState }))
    }

    const handleSearch = async (e) => {
        setSearchTextState(e.target.value)
        await getContactDetails(null, e.target.value);
};

    const getContactDetails = async (pagination, searchText) => {
        setIsLoading(true);
        if (pagination) {
            setPaginationData({
                offset: pagination?.offset, limit: pagination?.limit, sortField: pagination?.sortField,
                sortOrder: pagination?.sortOrder
            })
        }
        let req = pagination ? { ...pagination, searchText: searchText ? searchText : searchTextState }  : { ...paginationState, searchText: searchText ? searchText : searchTextState };
        const contactData = await dispatch(fetchContactDataByTenantId({ id: id, req: req }));
        setContacts(formatData(contactData?.payload?.data));
        setTotalCount(contactData?.payload?.count);
        setIsLoading(false);
    }

    const formatData = (info) => {
        let rows = [];
        let data = {};

        info?.forEach(element => {
            data = {
                name: element?.name ? element?.name : '--',
                title: element?.title ? element?.title : '--',
                mobile: element.phone ? element?.phone : '--',
                email: element.email ? element?.email : '--',
                address: element.address ? element?.address : '--',
                comments: element.comments ? element?.comments : '--',
                action: <ActionComponent disableEdit={!canAccess( 'update', 'tenants')} onDropdownClick={handleActionMenuOpen} disabledropDown={!canAccess( 'delete', 'tenants')} row={element} onEditClick = {openEditForm}/>

            };

            rows.push(data);
        });


        return rows;
    }

    useEffect(() => {
        if (TableTypes?.length) {
            let filterCols = TableTypes?.find(
                (obj) => obj?.category === "Contact_Details"
            );
            setTableColumns(filterCols?.columns);
        }
        getContactDetails();

        return() =>{
            handleActionMenuClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        setOpenContact(true);
        seteditData({});
    };

    const handleClose = () => {
        setOpenContact(false);
        seteditData({});
    };

    const handleActionMenuOpen = (e, row) => {
        setopenActionMenu(e.currentTarget);
        seteditData(row);
    }

    const handleActionMenuClose = () => {
        setopenActionMenu(null)
    }

    const openEditForm = (row) => {
        setOpenContact(true);
        seteditData(row);
    };

    const handleDeleteConfirm = () => {
        setOpenDeleteConfimation(!openDeleteConfimation ? true : false)
    }

    const handleDelete = async () => {
        try {
            let res = await dispatch(deleteContactData({ id: editData?.id }))
            if (res?.payload) {
                toast.success("Contact Deleted Successfully")
                getContactDetails();
            }
        } catch (err) {
            toast.error(err)
        }
        handleDeleteConfirm();
    }

    return (
        <MuiThemedBox className="main_inner_wrap chnagelog__wrap">
            <Breadcrumbs
                secondaryText={"Contact Details"}
                primaryTextPath={"tenants"}
                text={'Tenants'}
            />
            <Box className="filter_wrap" >
            <SearchInputField handleClick={handleSearch} />
            <Box className="flex_wrap" >
                <ButtonLayout
                    text={`contact`}
                    buttonStyleType={"outlined"}
                    icon={
                        <FontAwesomeIcon
                            icon="fa-solid fa-plus"
                            className="iconPadding"
                        />
                    }
                    Iconstyle={{ marginRight: '8px' }}
                    handleClick={handleClick}
                />

            </Box>
            </Box>
            <Box >
                <TableComponent
                    className="rdt_table_wrap"
                    columns={tableColumns}
                    rows={contacts}
                    // customStyles={customStyles}
                    isLoading={isLoading}
                    onPageChange={getContactDetails}
                    totalRows={totalCount}
                />
            </Box>
            <Suspense fallback={<div></div>}>
                {openContact ?
                    <FormPopup open={openContact} className={"stepper__modal"}>
                        <CreateContact handleClose={handleClose} tenantId={id} editData={editData} isUpdateList={true} updateData={getContactDetails} />
                    </FormPopup> : null}
            </Suspense>

            <Suspense fallback={<div></div>}>
                {openDeleteConfimation && canAccess( 'delete', 'tenants') &&
                    <DeleteConfirmPopup
                        open={openDeleteConfimation}
                        close={handleDeleteConfirm}
                        deleteUser={handleDelete}
                        user={editData}
                        heading={`Contact : ${editData?.name} ?`}
                        text={"Are you sure you want to delete this Contact ?"}
                    />
                }
            </Suspense>

            <ActionMenu
                openMenuList={openActionMenu}
                open={open}
                canDelete={canAccess( 'delete', 'tenants')}
                handleActionMenuClose={handleActionMenuClose}
                editData={editData}
                openEditForm={openEditForm}
                canChnageLog={false}
                handleDelete={handleDeleteConfirm}
            />
        </MuiThemedBox>
    )
}

export default ContactDetails