import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { MuiThemedButton, MuiThemedCircularProgress } from 'components/common/MuiThemedComponents';
import { BlockUI } from 'ns-react-block-ui';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteConfirmPopup = ({ open, close, deleteUser,user, heading, text, isLoading = false }) => {

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{`Delete ${heading}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MuiThemedButton onClick={close} variant="contained" size="small" >Close</MuiThemedButton>
                    <BlockUI blocking={isLoading} loader={<MuiThemedCircularProgress />} className='flex'>
                    <MuiThemedButton onClick={() => deleteUser(user)} variant="contained" size="small" color="error">Delete</MuiThemedButton>
                    </BlockUI>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteConfirmPopup