import React from "react";
import ReactQuill from "react-quill";
import CustomToolbar, { formats, modules } from "./CustomToolbar";
import "react-quill/dist/quill.snow.css";
import "./customQuillEditorStyles.less";
import { useSelector } from "react-redux";

export const CustomQuillEditor = ({ notes, handleNotesChange }) => {
  const user = useSelector(state => state.user)

  return (
    <div className={` ${user?.themePreference === 'dark' ? " custom__quill__editor__dark__mode" : ""}  custom__quill__editor`}>
      <CustomToolbar />
      <ReactQuill
        key={user?.themePreference}
        theme="snow"
        value={notes}
        onChange={handleNotesChange}
        placeholder={"Add notes here..."}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default CustomQuillEditor;
