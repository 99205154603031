import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import MultiSelectDropDown from "components/common/MultiSelectDropDown";
import { useSelector } from "react-redux";

export const ServiceFilterList = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "wug",
    label: "Circuits",
  },
  {
    value: "cradlepoint",
    label: "Cradlepoint",
  },
  {
    value: "fortinet",
    label: "Fortinet",
  },
  {
    value: "velocloud",
    label: "SDWAN",
  },
  {
    value: "meraki",
    label: "WiFi",
  },
];

const roleList = [
	{
		value: "all",
		label: "All",
	},
	{
		value: "sangoma_devices",
		label:  "Sangoma Devices",
	},
	{
		value: "customer_devices",
		label:  "Customer Owned",
	},
]

const modeFilterOptionsList =[
	{
		value:'primary',
		label:'Primary'
	},
	{
		value:'reserved',
		label:'Reserved'
	},
]

function FilterStatusAndService({
	style,
	handleStatusFilter,
	handleServiceFilter,
	statusList,
	statusName,
	isNameFilter,
	isRoleFilter,
	siteNameFilter,
	setsiteNameFilter,
	siteNameList,
	tenantNameList,
	isStatusFiltrer,
	isServiceFilter,
	isTenantFilter,
	setRoleValue,
	roleValue,
	statusFilterValue,
	serviceFilterValue,
	tenantFilterValue,
	handleTenantFilter,
	containerWidth,
	onApplyClick,callingFrom, totalFilterCount,
	isModeFilterEnabled, modeFilterValue, handleModeFilter, disableApply,
	isDeviceStatusFilter,  handleDeviceStatusFilter,  deviceStatusFilterValue, StatusFilterList,
	highlightFilter, fromDeviceScreen
}) {
  const [statusFilter, setStatusFilter] = useState("");
  const [serviceFilter, setServiceFilter] = useState("");
  const [roleFilterValue, setroleFilterValue] = useState("");
  const [tenatFilter, setTenantFilter] = useState("");
  const user = useSelector((state) => state.user);


  useEffect(() => {
    if (statusFilterValue && Object?.keys(statusFilterValue)?.length) {
      setStatusFilter(statusFilterValue);
    } else {
      setStatusFilter("");
    }
    if (serviceFilterValue && Object?.keys(serviceFilterValue)?.length) {
      setServiceFilter(serviceFilterValue);
    } else {
      setServiceFilter("");
    }
	if(roleValue && Object?.keys(roleValue)?.length){
		setroleFilterValue(roleValue)
	}else{
		setroleFilterValue("")
	}
	if(tenantFilterValue && Object?.keys(tenantFilterValue)?.length){
		setTenantFilter(tenantFilterValue)
	}else{
		setTenantFilter("")
	}

  }, [statusFilterValue, serviceFilterValue,roleValue, tenantFilterValue]);

  const onlyNameFilter = isNameFilter && !isServiceFilter && !isStatusFiltrer;
  const onlyStatusFilter = !isNameFilter && !isServiceFilter && isStatusFiltrer;
  const onlyServiceFilter =
    !isNameFilter && isServiceFilter && !isStatusFiltrer;
  const anyTwoFilter =
    (isNameFilter && isServiceFilter && !isStatusFiltrer) ||
    (isNameFilter && !isServiceFilter && isStatusFiltrer) ||
    (!isNameFilter && isServiceFilter && isStatusFiltrer);



	const onChangeRoleFilter = (val, action) => {
		setRoleValue(val, action);
		setroleFilterValue(val);
	}

	const onChangeStatusFilter = (val, action) => {
		handleStatusFilter(val, action);
		setStatusFilter(val);
	}

	const onChangeServiceFilter = (val, action) => {
		handleServiceFilter(val, action);
		setServiceFilter(val);
	}

	const onChangeTenantFilter = (val, action) => {
		handleTenantFilter(val, action);
		setTenantFilter(val);
	}
    const deviceOptions = [{value: 'switch', label: 'switch'}];
	return (
		<Grid style={style} container spacing={"10px"} className="flex-start flex_wrap">
			{isNameFilter ? (
				<Grid md={onlyNameFilter ? 12 : 4} item>			
					<MultiSelectDropDown controlShouldRenderValue = {false}
						onChangeValue={setsiteNameFilter}
						value={siteNameFilter}
						options={siteNameList?.map((obj) => {
							return {
								value: obj.name,
								label: obj.name,
							};
						})}
						placeholder={"Device Name"}
						styles={{ width: "88%"}}
						onApplyClick = {onApplyClick}
						
					/>
				</Grid>
			) : null}
			{isRoleFilter ? (
				<Grid md={4} item>
					<MultiSelectDropDown controlShouldRenderValue = {false}
						onChangeValue={onChangeRoleFilter}
						options={roleList}
						placeholder="Role"
						styles={{ width: "94%"}}
						value={roleFilterValue}
						onApplyClick = {onApplyClick}/>
						
				</Grid>
			) : null}
			{isStatusFiltrer && (
				<Grid md={totalFilterCount == 4 ? 3: onlyStatusFilter ? 12 : isRoleFilter || totalFilterCount == 3  ?  4 : anyTwoFilter ? 6 :isTenantFilter? 2.66:4} item>
					<MultiSelectDropDown 
						highlightFilter={highlightFilter && !fromDeviceScreen}
						controlShouldRenderValue = {false}
						onChangeValue={onChangeStatusFilter}
						value={statusFilter}
						options={statusList}
						placeholder={statusName}
						containerWidth = {containerWidth}
						styles={{ width: "94%"}}
						onApplyClick = {onApplyClick}
						disableApply = {disableApply}
					/>
				</Grid>
			)}
			{isServiceFilter && (
				<Grid md={totalFilterCount == 4 ? 3: onlyServiceFilter ? 12 : isRoleFilter || totalFilterCount == 3 ?  4 : anyTwoFilter ? 6 :isTenantFilter? 2.66:4} item>
					<MultiSelectDropDown controlShouldRenderValue = {false}
						onChangeValue = {onChangeServiceFilter}
						value={serviceFilter}
						options={callingFrom === 'devices' ? [...ServiceFilterList, ...deviceOptions] : ServiceFilterList}
						placeholder="Service..."
						styles={{ width: "94%"}}
						containerWidth ={containerWidth}
						onApplyClick = {onApplyClick}
						disableApply = {disableApply}
					/>
				</Grid>
			)}
			{isTenantFilter && (
				<Grid md={totalFilterCount == 4 ? 3: onlyServiceFilter ? 12 : isRoleFilter || totalFilterCount == 3 ?  4 : anyTwoFilter ? 6 : isTenantFilter? 2.68:3.5} item>
					<MultiSelectDropDown controlShouldRenderValue = {false}
						onChangeValue ={onChangeTenantFilter}
						value={tenatFilter}
						options={tenantNameList}
						placeholder="Tenant..."
						styles={{ width: "94%"}}
						onApplyClick = {onApplyClick}
					/>
				</Grid>
			)}

			{isModeFilterEnabled && (
				<Grid md={totalFilterCount == 4 ? 3: onlyServiceFilter ? 12 : isRoleFilter || totalFilterCount == 3  ?  4 : anyTwoFilter ? 6 : isTenantFilter? 2.68:3.5} item>
					<MultiSelectDropDown controlShouldRenderValue = {false}
						onChangeValue ={handleModeFilter}
						value={modeFilterValue}
						options={modeFilterOptionsList}
						placeholder="Mode..."
						styles={{ width: "94%"}}
						onApplyClick = {onApplyClick}
					/>
				</Grid>
			)}

			{isDeviceStatusFilter && (
				<Grid md={totalFilterCount == 4 ? 3 : onlyServiceFilter ? 12 : isRoleFilter || totalFilterCount == 3 ? 4 : anyTwoFilter ? 6 : isTenantFilter ? 2.68 : 3.5} item>
					<MultiSelectDropDown controlShouldRenderValue={false}
						onChangeValue={handleDeviceStatusFilter}
						value={deviceStatusFilterValue}
						options={user?.tenant?.name !== "Sangoma"? StatusFilterList?.filter(item => item.value !== "maint") : StatusFilterList}
						placeholder="Device Status..."
						styles={{ width: "94%" }}
						onApplyClick={onApplyClick}
						highlightFilter={highlightFilter}
					/>
				</Grid>
			)}
		</Grid>
	);
}

export default FilterStatusAndService;
