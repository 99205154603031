/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, Suspense } from 'react'
import { Box } from '@mui/material';
import TableComponent from 'components/common/TableComponent/TableComponent';
import { useLocation  } from "react-router";
import { MuiThemedBox, ReactDataTableStyles } from 'components/common/MuiThemedComponents';
import Breadcrumbs from 'components/common/Breadcrumps';
import { DeviceCell, StatusCell} from './LinkMonitoring';
import { useDispatch, useSelector } from "react-redux";
import {fetchLinkMonitorData} from "store/devices";
import MinusIcon from "assets/icons/minus.svg";
import PlusIcon from "assets/icons/plus.svg";
import ColumnHeaderWithTooltip from 'components/common/TableComponent/ColumnHeaderWithTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipLabel, { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel';
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell';
import Timer from 'components/component/link/Timer';
import ServicePopup from 'components/component/createSite/ServicePopup';
import DataTable, { createTheme } from 'react-data-table-component';



export default function LinkMapping() {
    const [isLoading, setIsLoading] = useState(false)
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const [tableColumns, setTableColumns] = useState([]);
	const [openServicePopup, setOpenServicePopup] = useState(false);
	const [selectedRowData, setselectedRowData] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [nestedColumns, setNestedColumns] = useState([]);


	const ExpandedComponent = ({ data, ...props }) => {
		createTheme('table__dark__theme',
			ReactDataTableStyles
		);
		
		return (
			<div className="link__monitoring subComponent">
				<DataTable
					theme={user?.themePreference === 'dark' ? "table__dark__theme" : null}
					className={`  ${user?.themePreference === 'dark' ? 'rdt__table__darkMode' : ''}`}
					columns={props?.tableColumns}
					onRowClicked={props?.onRowClicked}
					data={data = data?.circuits?.map(i => {
						return {
							callingFrom: 'circuits',
							...i
						}
					})}
					noHeader={true}
					conditionalRowStyles={props?.conditionalRowStyles}
				/>
			</div>
		);
	};
   

	const columns = [
		{
			cell: (row) => { return <>{row.tenant_name} &nbsp; {row.is_vip_tenant ? <FontAwesomeIcon className='vip__icon' icon="fa-solid fa-crown" /> : ""}</> },
			name: "Tenant",
			title: "Tenant",
			sortable: true,
			wrap: true,
			minWidth: 170,
			isEnabled: true,
			sortField: "tenant_name"
		},
		{
			cell: (row) => 	{
				const siteName = row?.site_display_name ? row?.site_display_name : row?.site_name ? row?.site_name : null
				return (
					<Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center !important', height: '100%' }} >
						{siteName &&
							<StyledTooltip className='site__display__name__overview__tooltip' placement="bottom" title={"Site Overview"} style={{ top: '10px', left: '4px' }}>
								<FontAwesomeIcon style={{
									fontSize: '14px', paddingRight: '8px'
								}} icon="fa-solid fa-square-poll-horizontal" onClick={(e) => handleServicePopup(e, row, true)} />
							</StyledTooltip>
						}
						<CopyTextCell text={siteName ? siteName : "--"} />
					</Box>
				)
			},
			name: "Site Display Name",
			title: "Site Display Name",
			id: "site_display_name",
			sortable: false,
			wrap: true,
			isEnabled: true,
			minWidth: 280,
		},
		{
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row?.site_name}/>,
			name: "Site Name",
			title: "Site Name",
			id: "site_name",
			sortable: false,
			wrap: true,
			isEnabled: false,
			minWidth: 280,
		},
	
		{
			cell: (row) => <DeviceCell row = {row}/>,
			name: "Device Name",
			title: "Device Name",
			sortable: true,
			sortField: 'name',
			wrap: true,
			isEnabled: true,
			minWidth: 280,
		},
	
		{
			cell: (row) => <TooltipLabel text = {row.isp_name}/>,
			name: "Vendor",
			title: "Vendor",
			id: "vendor",
			sortField: 'isp_name',
			sortable: true,
			minWidth: 190,
			maxWidth: 190,
			wrap: true,
			isEnabled: true,
		},
		{
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.ip_address}/>,
			name: "IP",
			title: "IP",
			sortable: false,
			isEnabled: true,
			wrap: true,
			minWidth: 190,
			maxWidth: 190,
		},
	
		{
			name: "Device | Circuit",
			title: "Device | Circuit",
			sortable: false,
			wrap: true,
			isEnabled: true,
			minWidth: 180,
			maxWidth: 180,
			cell: (row) => <StatusCell row = {row}/>
		},
		{
			cell: (row) => {
				return row.event_time && row.link_status?.toLowerCase() !== "n/a" ? <Timer targetDate={row.event_time} /> : "--";
			},
			name: "Since",
			title: "Since",
			sortable: true,
			wrap: true,
			minWidth: 200,
			maxWidth: 200,
			isEnabled: true,
			sortField: "event_time"
		},
		{
			cell: (row) => <TooltipLabel text = {row.type ? row.type : "-"}/>,
			name: "Type",
			title: "Type",
			sortable: false,
			minWidth: 170,
			wrap: true,
			isEnabled: true,
			maxWidth: 170,
		},
		{
			cell: (row) => {
				if (row?.service === "velocloud") {
					return (row?.link_mode === "reserved" ? "Reserved": "Primary")
				}
				else {
					return "--"
				}
	
			},		
			name: <ColumnHeaderWithTooltip tooltipText={'Only for SDWAN'} columnName={'Mode'} />,
			title: 'Mode',
			isEnabled: true,
			minWidth: 190,
			maxWidth: 190,
			sortable: false,
			wrap: true,
		},
		{
			cell: (row) => {
				return row?.link_speed_tx && row?.link_speed_rx ? (
					<div className="link_speed">
						{row?.link_speed_tx ? (
							<span className="speed">
								<FontAwesomeIcon icon="fa-solid fa-arrow-up" color="green" />
								{row.link_status?.toLowerCase() === "down" || row.link_status?.toLowerCase() === "maint" ? '0' :
								JSON.parse(row.link_speed_tx)?.value} mb/sec
							</span>
						) : null}
						{row?.link_speed_rx ? (
							<span className="speed">
								<FontAwesomeIcon icon="fa-solid fa-arrow-down" color="red" />
								{row.link_status?.toLowerCase() === "down" || row.link_status?.toLowerCase() === "maint" ? '0' :
								JSON.parse(row.link_speed_rx)?.value} mb/sec
							</span>
						) : null}
					</div>
				) : (
					"--"
				);
			},
			name: <ColumnHeaderWithTooltip tooltipText={'Only for SDWAN'} columnName={'Speed'} />	,
			title: 'Speed',
			minWidth: 190,
			maxWidth: 190,
			sortable: false,
			wrap: true,
			isEnabled: true,
		},
		{
			cell: (row) => {
				const calculatedLatency=Math.round(row.link_latency * 100) / 100
				const latency =row?.service === "velocloud" && calculatedLatency != 0 ?  `${calculatedLatency} ms`:'--'
				return (
					<span data-tag="allowRowEvents" style={{ textTransform: "lowercase" }}>
						{latency}
					</span>
				)
			},
			name: <ColumnHeaderWithTooltip tooltipText={'Only for SDWAN'} columnName={'Latency'} />	,
			title: 'Latency',
			sortable: false,
			wrap: true,
			isEnabled: true,
			minWidth: 170,
			maxWidth: 170,
		},
		{
			cell: (row) => {
				return row.service === "velocloud"
					? "SDWAN"
					: row?.service === "meraki"
						? "WiFi"
						: row?.service === "wug"
							? "Circuits"
							: row?.service === "cradlepoint"
								? "Cradlepoint"
								: row?.service === "fortinet"
									? "Fortinet" : "--";
			},
			title: 'Service',
			name: "Service",
			sortable: false,
			minWidth: 170,
			maxWidth: 170,
			wrap: true,
			isEnabled: true,
		},
	
	
	];

	const getNestedColumns = (columns) =>{
		let formatedCol = [...columns];
	
		formatedCol.unshift({
		selector: () => <FontAwesomeIcon icon="fa-solid fa-chevron-right" />,
		name: "arrow",
		sortable: false,
		wrap: true,
		isEnabled: true,
			style: {
				minWidth: "38px !important",
				maxWidth: "38px !important",
				marginLeft: '10px'
			},
		})

		return formatedCol

	}

	const onColumnChange= (col) =>{
		let updateColumns = col?.filter(i => i?.isEnabled);
		const columns = getNestedColumns(updateColumns)	
		setNestedColumns(columns)
	}


	const handleServicePopup = (e, data, open) =>{
		e?.stopPropagation();
		setselectedRowData(data); 
		setOpenServicePopup(open);
		setAnchorEl(e?.currentTarget)
	}

	// const navigate = useNavigate();
    const { state } = location || {};

    const fetchLinkData = async() => {
        setIsLoading(true);
        const response = await dispatch(
			fetchLinkMonitorData({
				tenantName: 'Sangoma',
				params: { filter: JSON.stringify([{key: 'netbox_id', value: [state.netboxId]}, 
                    {key: 'monitor_device_data.service', value: ["velocloud","meraki","cradlepoint","fortinet"]}])},

			}));
            setRows(response?.payload?.data);
            setIsLoading(false);
    }

    useEffect(() => {
        if (user?.tenant?.name !== 'Sangoma') {
			
			let filteredCol = columns?.filter(data => {
				return data.name !== 'Tenant'
			});
			setTableColumns(filteredCol);
			const nestedColumns = getNestedColumns(filteredCol);
			setNestedColumns(nestedColumns)
        } else {
			const nestedColumns = getNestedColumns(columns);
			setNestedColumns(nestedColumns)
			setTableColumns(columns)
		}
        fetchLinkData();

		return() =>{
			handleServicePopup(null, null, false)
		}
    },[])	

  return (
    <MuiThemedBox className="main_inner_wrap link__monitoring__dashboard">
    <Breadcrumbs style={{padding: '10px 5px 15px 5px'}}
        secondaryText={"LINK Details"}
        primaryTextPath={"link-monitoring"}
        text={'LINK'}
    />
    <Box >
        <TableComponent
            className="rdt_table_wrap hover_table"
            columns={tableColumns}
            rows={rows}
            isLoading={isLoading}
			onColumnChange={onColumnChange}
        pagination={false}
            expandableRowExpanded={() => {return true}}
            expandableRowsComponent={ExpandedComponent}
            expandableRowsComponentProps={{tableColumns: nestedColumns}}
            expandableRows={columns.find((o) => o.name === "Device Name")}
            expandableIcon={{
                collapsed: (
                    <img src={PlusIcon} alt="open" className="expand__icon" />
                ),
                expanded: (
                    <img src={MinusIcon} alt="close" className="expand__icon" />
                ),
            }}
        />
    </Box>
		<Suspense fallback={<div></div>}>
			{openServicePopup &&
				<ServicePopup
					callingFrom={'link-monitor'}
					rowData={{
						id: selectedRowData?.site_id,
						site_display_name: selectedRowData?.site_display_name,
						name: selectedRowData?.site_name,
						tenant: {
							id: selectedRowData?.tenant_id
						}
					}}
					handleClosePopup={() => handleServicePopup(null, null, false)}
					open={openServicePopup}
					renderPopper={true}
					anchorEl={anchorEl}
				/>
			}
		</Suspense>
</MuiThemedBox>
  )
}
