import { Link } from 'react-router-dom';
import NotFoundImg from 'assets/icons/notfound.svg';
import React from 'react';
import Button from "@mui/material/Button";

const NotFound = () => {

	return (
		<div className='not-found'>
			<img alt="Not Found" src={NotFoundImg} />
			<h1>404 - Page Not Found</h1>
			<p>The requested page does not exist.</p>
			<Link to="/nf-status" >
				<Button variant="contained" size="small" className="base__btn">Return to home</Button>
			</Link>
		</div>
	)
}

export default NotFound