import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { MuiThemedButton } from 'components/common/MuiThemedComponents';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationPopup(props) {
  return (
    <Dialog
    open={true}
    TransitionComponent={Transition}
    keepMounted
    onClose={props?.onClose}
    aria-describedby="alert-dialog-slide-description"
>
    <DialogTitle>{`${props?.heading}`}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            {props?.text}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <MuiThemedButton onClick={props?.onConfirm} showLoader={props?.onConfirmLoader} variant="contained" size="small" color="success">{props?.confirmText}</MuiThemedButton>
        <MuiThemedButton onClick={props?.onClose} variant="contained" size="small" >{props?.closeText}</MuiThemedButton>
    </DialogActions>
</Dialog>
  )
}
