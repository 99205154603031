import React, { useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';

const Timer = ({ targetDate }) => {
    const [timeRemaining, setTimeRemaining] = useState('00:00:00');

    const DurationFormat = d=>{
        let year = ''; let month = ''; let day = ''; let week = '';
        if (d.years > 0)    year = `${d.years + (d.years > 1 ? ' years ' : ' year ')}`
        if (d.months > 0)   month = `${d.months + (d.months > 1 ? ' months ' : ' month ')}`
        if (d.weeks > 0) week = `${d.weeks + (d.weeks > 1 ? ' weeks ' : ' week ')}`
        if (d.days > 0) day = `${d.days + (d.days > 1 ? ' days' : ' day')}`
        if (d.days === 0  && d.years === 0 && d.months === 0 && d.weeks === 0)   {
            
            return `${(''+d.hours).padStart(2,"0")}:${(''+d.minutes).padStart(2,"0")}:${(''+parseInt(d.seconds)).padStart(2,"0")}`

        }

        return (year + month + week + day)
    }

    const calSiteUptime = () => {
        const siteUpDate = DateTime.fromMillis(parseInt(targetDate));
        const currentDate = DateTime.now();
        const formatted = Interval
            .fromDateTimes(siteUpDate, currentDate)
            .toDuration(['years','months', 'days', 'weeks',
            'hours', 'minutes', 'seconds'])
            .toObject();
         return DurationFormat(formatted);

    }

    useEffect(() => {
        setTimeRemaining(calSiteUptime());
        const interval = setInterval(() => {
            setTimeRemaining(calSiteUptime());
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetDate]);


    return (
        <div>
            <TooltipLabel text ={timeRemaining}/>
        </div>
    );
};

export default Timer;
