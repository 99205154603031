import React, {useRef, useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components } from "react-select";
import TooltipLabel from './TooltipLabel/TooltipLabel';
import { useSelector } from 'react-redux';
import { MuiThemedButton } from './MuiThemedComponents';

export default function MultiSelectDropDown({onChangeValue, value, options, placeholder, containerWidth, styles, className,onApplyClick, disableApply, hideFilterIcon, menuPlacement,highlightFilter,  ...props}) {
    const valueRef = useRef(value);
    valueRef.current = value;
	const user = useSelector((state) => state.user);
	const [searchText, setSearchText] = useState('')
	const customStyle = {
		option: (base) => ({
			...base,
			textTransform: 'capitalize',
		}),
		valueContainer: (base) => ({
			...base,
			textTransform: 'capitalize',
		}),
		container: (base) => ({
			...base,
			width: containerWidth
		})
	}

	const darkThemeReactSelectStyles = {
		option: (provided, state) => ({
			...provided,
			textTransform: 'capitalize',
			borderBottom: '1px solid #caced6',
			color: state.isSelected ? '#262a30' : '#fff',
			backgroundColor: state.isSelected ? '#999eaa' : '',
			boxShadow: '0 0 0 1px hsl(0deg 2.98% 58.56% / 10%), 0 4px 11px hsl(0deg 6.08% 86.38% / 10%)',
			'&:active': {
				backgroundColor: '#dee1e7db',
				color: ' #393030'
			}
		}),
		control: (provided) => ({
			...provided,
			boxShadow: '0 0 0 1px #54606d',
			backgroundColor: 'transparent !important',
			borderColor: '#383d43 !important',
			textTransform: 'capitalize',

		}),
		singleValue: (provided) => ({
			...provided,
			color: '#fff'
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: '#31363F !important',
		}),
		container: (base) => ({
			...base,
			width: containerWidth
		})

	}
	
	const formatOptionLabel = (option) => (
		<TooltipLabel text={option?.label}/>
	);

	const isSelectAllSelected = () => valueRef.current.length === options.length;
    const onChange = (newValue, actionMeta) => {
		const cloneSearchText = searchText;
		const { action, option, removedValue } = actionMeta;
		if (action === "select-option" && option.value === 'all') {
			onChangeValue(options, action);
		} else if ((action === "deselect-option" && option.value === 'all') || (action === "remove-value" && removedValue.value === 'all')) {
			onChangeValue([], action); 
		} else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
			onChangeValue(newValue.filter(({ value }) => value !== 'all'), action);
		} else if (actionMeta.action === "select-option" && newValue.length === options.filter(i => i.value !== 'all').length){
			onChangeValue(options, action);
		}
		else {
			onChangeValue(newValue, action);
		}
		setSearchText(cloneSearchText);
	};
  const selectStyle = user?.themePreference === 'dark' ? darkThemeReactSelectStyles : customStyle;

  return (
    <Select 
    styles={{...selectStyle, ...styles}}
    onChange={onChange}
    value={value}
	onApplyClick = {onApplyClick}
	disableApply = {disableApply}
    isMulti
	inputValue = {searchText}
	onInputChange = {val => setSearchText(val)}
	formatOptionLabel = {formatOptionLabel}
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    options={options}
    placeholder={placeholder || '-Select-'}
	classNamePrefix={`${user?.themePreference === 'dark'? "select-dropdown react__select__dark__mode":"select-dropdown"}  `}
    components={{ 
        Control, 
        MultiValue : Value,
        Option: InputOption,
		Menu: Menu,
    }}
    className={`service_filter ${className} ${highlightFilter ? ' highlighted__filter':''}`}
	hideFilterIcon = {hideFilterIcon}
	menuPlacement = {menuPlacement || 'auto'}
	isLoading = {props?.isLoading}
/>
  )
}


const Control = ({ children, ...props }) => {
	const { hideFilterIcon } = props.selectProps;
	return (
    <components.Control style={{ width: "auto" }} {...props}>
      {!hideFilterIcon && <div style={{ paddingLeft: "10px", color: "rgba(0, 0, 0, 0.54)" }}>
        <FontAwesomeIcon icon="fa-solid fa-filter" />
      </div>}
      {children}
    </components.Control>
	)
};

const InputOption = ({
	getStyles,
	isDisabled,
	isFocused,
	isSelected,
	children,
	innerProps,
	...rest
}) => {
	const [isActive, setIsActive] = useState(false);
	const onMouseDown = () => setIsActive(true);
	const onMouseUp = () => setIsActive(false);
	const onMouseLeave = () => setIsActive(false);

	// styles
	let bg = "transparent";
	if (isFocused) bg = "#eee";
	if (isActive) bg = "#B2D4FF";

	const style = {
		alignItems: "center",
		backgroundColor: bg,
		color: "inherit",
		display: "flex "
	};

	// prop assignment
	const props = {
		...innerProps,
		onMouseDown,
		onMouseUp,
		onMouseLeave,
		style
	};
	return (
		<components.Option
			{...rest}
			isDisabled={isDisabled}
			isFocused={isFocused}
			isSelected={isSelected}
			getStyles={getStyles}
			innerProps={props}
		>
			<input type="checkbox" checked={isSelected} style={{marginRight:'6px'}} readOnly/>
			{children}
		</components.Option>
	);
};

const Value = ({ index, getValue }) => {
	
	const selectedOptions = getValue();
	const allSelected = selectedOptions && selectedOptions?.length && selectedOptions?.filter(i => typeof i.value === 'string' && i?.value?.toLowerCase() === 'all')
	const displayValue =  allSelected?.length > 0 ? allSelected?.[0]?.label
	: 		selectedOptions?.map(item => item?.label)?.join(", ")
	return (!index &&
		<TooltipLabel removeDataTag={true} width={'85%'} text={`${displayValue}`} />);
}

const Menu = ({ children, ...props }) => {
	const {onApplyClick, placeholder, disableApply} = props.selectProps
	return (
		<components.Menu {...props}>
			<>
				{children}
				{onApplyClick && <div className='multi-select-btn'>
				<MuiThemedButton onClick={() => onApplyClick(placeholder)} className="import_btn btn" variant="contained" size="small" color="primary" disable = {disableApply}>
					APPLY
				</MuiThemedButton>
				</div>}
			</>
		</components.Menu>
	);
};
