import { Link } from 'react-router-dom';
import NotFoundImg from 'assets/icons/notfound.svg';
import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useAuth } from 'hooks/useAuth';

const AccessDenied = () => {

	const auth = useAuth();

	return (
		<div className='not-found'>
			<img alt="Not Found" src={NotFoundImg} />
			<h1>403 - Access Denied</h1>
			<p>You don&apos;t have permission to access this page.</p>
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
				<Link to="/nf-status" >
					<Button variant="contained" size="small" className="base__btn">Return to home</Button>
				</Link>
				&nbsp;&nbsp;
				<Button variant="contained" color='error' size="small" onClick={auth.logout} className="base__btn">Logout</Button>
			</Box>
		</div>
	)
}

export default AccessDenied