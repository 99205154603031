import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import { Provider } from 'react-redux';
import PKCEAuth from "@star2star/cpaas-pkce-auth";

const render = () => {
	const root = ReactDOM.createRoot(document.getElementById('root-nfstatus'));
	root.render(
		<div>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</div>
	);
}

	try {
		if(location.pathname !== "/"){
			localStorage.setItem("redirectEndPoint", location?.pathname);
		}		
		const params = {
			"clientId": process.env.REACT_APP_OAUTH_CLIENT_ID,
			"authorizeEndpoint": process.env.REACT_APP_OAUTH_AUTHORIZE_ENDPOINT,
			"scope": process.env.REACT_APP_OAUTH_SCOPE,
			"redirectUri": process.env.REACT_APP_REDIRECT_URL,
			"tokenEndpoint": process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT,
			"logoutEndpoint": process.env.REACT_APP_OAUTH_LOGOUT_ENDPOINT,
		}
		PKCEAuth.setConfig(params);
	} catch (e) {
		console.error("PKCEAuth error:", e);
	}
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
