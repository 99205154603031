import React from 'react'
import { MuiThemedCircularProgress } from './MuiThemedComponents'

const ProgressLoading = () => {

	return (
		<div className='progress'>
		<MuiThemedCircularProgress  /> 
			Please wait...
		</div>
	)
}

export default ProgressLoading