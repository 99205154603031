import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@mui/material'
import ButtonLayout from 'components/common/Button'
import CopyTextCell from "components/common/CopyTextCell/CopyTextCell"
import { MuiThemedBox, MuiTypography } from 'components/common/MuiThemedComponents'
import { formatDate } from 'components/common/utils'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTicketComments } from 'store/tickets'
import { typeMapper } from './ticketsConstants'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel'
import { useCanAccess } from 'services/canACLAccess'

const removeCreatedByText = (text) => {
    if(text) {
        const index = text?.indexOf('Added By:');
        if (index !== -1) {
            text = text?.substring(0, index);
        }
        return text?.trim();
    }

};


const getNameEmailFromComment = (comment, createdBy) => {
    if (comment && comment?.length > 0) {
        const addedByIndex = comment?.indexOf("Added By: ");
        const emailIndex = comment?.indexOf("Email: ");

        if (addedByIndex !== -1 && emailIndex !== -1) {
            const addedBySubstring = comment?.substring(addedByIndex + "Added By: ".length, emailIndex)?.trim();
            const emailSubstring = comment?.substring(emailIndex + "Email: ".length)?.trim();

            const parts = addedBySubstring?.split('(');
            const name = parts[0]?.trim();
            const role = parts[1]?.replace(')', '').trim();
            const email = emailSubstring;
            return { name, role, email };
        } else {
            return { name: createdBy, role: null, email: null };
        }
    }
};


const CommentComponent = ({ element, valueKey, redirectToLinkMonitoring, ticketingSystem, ticketId, ticketType }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const createdByText = `\nAdded By: ${user?.name} (NetOps)\nEmail: ${user?.email}`;
    const canAccess = useCanAccess();
    const originalComment = element?.comment;
    const createdBy = element?.createdBy
    const commentWithoutCreatedBy = removeCreatedByText(originalComment);
    const [value, setValue] = useState(commentWithoutCreatedBy);
    const [comment, setComment] = useState(commentWithoutCreatedBy)
    const [isEdit, setIsEdit] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    // REMINDER: It has to be uncommented for PHASE 2 RELEASE
    const enableEdit = useCallback(() => {
        let commentedEmail = getNameEmailFromComment(originalComment, createdBy);
        return commentedEmail?.email === user?.email && canAccess( 'update', 'tickets');
    }, [originalComment, user?.email, createdBy]);

    const updateComment = useCallback(async () => {
        if (canAccess('update', 'tickets')) {

            const updatePayload = {
                "id": element?.commentId,
                "comment": value + createdByText,
                type: typeMapper[valueKey],
                ticketingSystem: ticketingSystem,
                ticketId: ticketId,
                createdBy: element?.createdBy,
                createdDate: element?.createdDate,
                commentType: element?.commentType || 'external'
            }
            setIsUpdating(true)
            try {
                const response = await dispatch(updateTicketComments(updatePayload));
                if (response?.payload?.updatedComment) {
                    let newComment = response?.payload?.updatedComment;
                    let commentWOCreatedBy = removeCreatedByText(newComment?.comment);
                    setComment(commentWOCreatedBy);
                    setValue(commentWOCreatedBy)
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsUpdating(false)
                setIsEdit(false)
            }
        }

    }, [valueKey, value])

    const parseCommentForTextEditor = (comment) => {
        if(comment) {
            const commentInfo = comment?.split("|");
            if (commentInfo && commentInfo.length === 1) {
                return commentInfo[0];
            } else if (commentInfo && commentInfo?.length > 1) {
                return commentInfo?.map(item => item?.trim()).join('\n');
            }
        }
    };

    const parseComment = useCallback((comment) => {
        const commentInfo = comment?.split("|");    
        if (commentInfo && commentInfo?.length == 1) {
            return [{ title: "Netops Comment", value: commentInfo?.[0] }]
        }
        else if (commentInfo && commentInfo?.length > 1) {
            const parsedCommentInfo = commentInfo && commentInfo?.length > 0 && commentInfo?.map(item => {
                // eslint-disable-next-line no-unsafe-optional-chaining
                let [title, value] = item?.split(/:(.+)/).map(item => item.trim());
                title = title?.trim();
                let newTitle = title?.toLowerCase() == "device" ? "Device Name" : title?.toLowerCase() === "ip" ? "IP Address" : title?.trim()
                return {
                    title: newTitle,
                    value: value ? value.trim()?.toLowerCase() == "down" ? "Down" : value?.trim() : ''
                };
            }
            )
            return parsedCommentInfo
        }
    }, [])

    const renderValue = (item) => {
        const value = item?.value || "--";
        let commentValue =comment && comment?.length > 0 && parseComment(comment);
        const ipAddress = commentValue?.find(i => i.title.toLowerCase() === "ip address");
       
        switch (item?.title?.toLowerCase()) {
            case "ip address":
                return <CopyTextCell text={value} />;
            case "device name":
                return (
                    <Typography
                        component="div"
                        className="description-value device_name"
                        onClick={(e) => {
                            e?.stopPropagation()
                            redirectToLinkMonitoring(value, ipAddress?.value)
                        }}
                    >
                        <TooltipLabel text={value} />
                    </Typography>
                );
            case "status":
                return (
                    <MuiTypography component="div" className={`description-value ${value?.toLowerCase()}`}>
                        {value}
                    </MuiTypography>
                );
            default:
                return <div className="comment__item__value">
                    <TooltipLabel text={value} />
                </div>;
        }
    };


    const titleInfo = useMemo(() =>
        getNameEmailFromComment(originalComment, createdBy),
        [getNameEmailFromComment, originalComment, createdBy]);

    if (comment) {
        return (
            <MuiThemedBox className='log__entry__wrapper'>
                <div className='log__entry__wrapper-header'>
                    <div className='title'>
                        {titleInfo?.name}
                        {titleInfo?.role && ` (${titleInfo?.role})`}
                    </div>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {enableEdit() &&
                            <FontAwesomeIcon style={{ paddingRight: "10px", cursor: "pointer" }} icon="fa-solid fa-pen-to-square" onClick={(e) => { e?.stopPropagation(); setIsEdit(true); setValue(comment) }} />
                        }
                        <div className="log__entry__date">{ticketingSystem === "salesforce" ? formatDate(element?.lastModifiedDate) : formatDate(element?.createdDate)}</div>
                    </Box>

                </div>
                <div className={`log__entry__message`} >
                    {!isEdit ?
                        <div className="comment">
                            {ticketType === "external" ? <div className="comment__item__value">
                                {comment && comment?.length > 0 && comment?.split('\n')?.map((line, index) => (
                                    <>
                                        {line}
                                        {index < comment?.split('\n').length - 1 && <br />}
                                    </>
                                ))}
                            </div> :
                             comment &&   parseComment(comment) && parseComment(comment)?.length > 0 &&
                                parseComment(comment)?.map((item, idx) => {
                                    if (item?.title === "Netops Comment") {
                                        return (
                                            <div key={idx} className="comment__item__value">{item?.value}</div>
                                        )
                                    } else {
                                        return (
                                            <div className="comment__item" key={idx}>
                                                <div className="comment__item__title">{item?.title}: &nbsp;</div>
                                                {renderValue(item)}
                                            </div>
                                        )
                                    }

                                })}
                        </div>
                        :
                        <>
                            <div className='comment__edit__wrapper'>
                                <textarea
                                    onChange={(e) => { setValue(e?.target?.value) }}
                                    value={parseCommentForTextEditor(value)}
                                    rows={3}
                                />
                            </div>

                            <div className='log__entry__wrapper-content-footer'>
                                <ButtonLayout
                                    disabled={value?.length === 0}
                                    handleClick={(e) => { e?.stopPropagation(); setIsEdit(false); setValue(commentWithoutCreatedBy) }}
                                    buttonStyleType={"outlined"} text={'Cancel'}>
                                </ButtonLayout>
                                <ButtonLayout buttonStyleType="filled"
                                    isLoading={isUpdating}
                                    handleClick={(e) => { e?.stopPropagation(); updateComment(); }}
                                    type="submit" text={"Update"}
                                    disabled={value?.length === 0}
                                >
                                </ButtonLayout>
                            </div>
                        </>
                    }
                </div>
            </MuiThemedBox>
        )

    }
}

export default CommentComponent
