import React, {useEffect, useState,useRef} from 'react'
import Tooltip,  { tooltipClasses } from "@mui/material/Tooltip";
// import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';


export default function TooltipLabel({text, width, ...props}) {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const description = ref.current;
     
        description && description.scrollWidth > description.clientWidth &&
          setIsOverflowed(true);
      }, [props?.removeDataTag, text]);

      const Div = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: props?.fontSize || '13px',
        width:width,
}

  return (
    <React.Fragment>
    <StyledTooltip key={text} title={text} disableHoverListener={!isOverflowed} arrow width={width ? width : '100%'} {...props}>
        <div  ref = {ref} style = {Div} data-tag={props?.removeDataTag  ? null :"allowRowEvents"} {...props}>
          {text}
        </div>
    </StyledTooltip>
  </React.Fragment>
  )
}


export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} style={{width : props?.width, maxWidth: props?.maxWidth}}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              // offset: [0, 14],
            },
          },
        ],
      },
    }}/>
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
    },
    [`& .${tooltipClasses.arrow}::before`]: {
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#fff",
      fontFamily: "Arial",
      fontSize: '12px',
      marginTop: '0px !important',
    },
  }));