import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Breadcrumbs from "components/common/Breadcrumps";
import { TableTypes } from "constant/TableColTypes";
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchChangeLogs, setChangelogPath } from "store/changelogs";
import { setLoading } from "store/user";
import {  filterColumns } from "utils/Utilities";
import { components } from "react-select";
import DateRangeLayout from "components/common/DateRange";
import Grid from '@mui/material/Grid';
import { MuiThemedBox, MuiThemedButton, ReactDataTableStyles, ThemedReactSelect } from "components/common/MuiThemedComponents";
import SearchInputField from "components/common/SearchInputField";
import TableComponent from "components/common/TableComponent/TableComponent";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const customStyles = {
    tableWrapper: {
        color: "#000",
        overflowX: "unset",
        overflowY: "unset",
    },
};

const Control = ({ children, ...props }) => (
    <components.Control style={{ width: "auto" }} {...props}>
        <div style={{ paddingLeft: "10px", color: "rgba(0, 0, 0, 0.54)" }}>
            <FontAwesomeIcon icon="fa-solid fa-filter" />
        </div>
        {children}
    </components.Control>
);

const ChangeLogs = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [tableColumns, setTableColumns] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [changeLogsType, setChangeLogsType] = useState();
    const [selectedDate, setselectedDate] = useState({});
    const [totalCount, setTotalCount] = useState(0)
    const [searchTextState, setSearchTextState] = useState(null);
    const [filterState, setFilterState] = useState([]);

    const navigate = useNavigate();

    const changeLogsTypeList = [{
        value: "TENANT",
        label: "TENANT"
    }, {
        value: "SITE",
        label: "SITE"
    }, {
        value: "DEVICE",
        label: "DEVICE"
    }];

    useEffect(() => {
        if (TableTypes?.length) {
            let filterCols = TableTypes?.find(
                (obj) => obj?.category === "change-logs"
            );
            setTableColumns(filterCols?.columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {

        if (!user.tenant) {
            return;
        }
        getChangeLogsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.tenant]);


    const handleDateChange = async (date) => {
        let cloneFilter = filterState || [];
        const value = {
            start: date?.startDate,
            end: date?.endDate,
        };
        await setselectedDate(value);
        cloneFilter = cloneFilter?.filter(i => i.key !== 'datetime');
        cloneFilter.push({key: 'datetime', value: value});
        setFilterState(cloneFilter);
        getChangeLogsData(null, null, {filter: cloneFilter?.length > 0 ? JSON.stringify(cloneFilter) : null});
    };

    const filterData = async (val) => {
        setChangeLogsType(val)
        let cloneFilter = filterState || [];
        cloneFilter = cloneFilter?.filter(i => i.key !== 'type');
        val?.value && cloneFilter.push({key: 'type', value: [val?.value]});
        setFilterState(cloneFilter);
        getChangeLogsData(null, null, {filter: cloneFilter?.length > 0 ? JSON.stringify(cloneFilter) : null});
    }

    const handleChangeLogDetails = (changelogId) => {
		navigate('/nf-status/change-logs/changelogs-details');

        dispatch(setChangelogPath({
            id: changelogId,
            type: "change-logs",
            primaryText: 'ChangeLogs'
        }));
	};

    const formatData = (dataList) => {
        let rows = [];
        dataList?.map((row) => {
            let status = (row && row.custom_fields && row.custom_fields.action_type) ? row.custom_fields.action_type :
            (row && row.action) ? row.action : '---';
            let statusClass = '';
            switch (status) {
                case "updated":
                    statusClass = 'status status_inactive';
                    break;
                case "created":
                    statusClass = 'status status_active';
                    break;
                case "deleted":
                    statusClass = 'status status_down';
                    break;
            }
            rows?.push({
                datetime: getDateTime(row?.datetime),
                type: row && row.assigned_object_type ? resolveChangeLogsType(row.assigned_object_type) : row && row?.type ? row?.type: "UNKNOWN",
                username: row && row.custom_fields && row.custom_fields.username ? row.custom_fields.username : row && row.username ? row.username:"---",
                activity_type: status ? (<span className={statusClass}>{status}</span>) : "",
                name: row && row?.custom_fields?.circuit_name_journal ?  row?.custom_fields?.circuit_name_journal: row.assigned_object && row.assigned_object.display ? row.assigned_object.display : row && row?.name ? row?.name : "---",
                comments: row && row.comments ? row.comments : "---",
                details: row?.changelog_id ? <MuiThemedButton onClick={()=> {handleChangeLogDetails(row?.changelog_id)}} variant="contained" size="small" >Details</MuiThemedButton> : "--"            })
        }
        );
        return rows;
    };
    const getDateTime = (dateTime) => {
        return `${DateTime.fromISO(dateTime).toISODate()} ${DateTime.fromISO(
            dateTime
        ).toLocaleString(DateTime.TIME_SIMPLE)}`;
    };

    const resolveChangeLogsType = (objType) => {
        switch (objType) {
            case 'dcim.site':
                return 'SITE';
            case 'tenancy.tenant':
                return 'TENANT';
            case 'tenancy.contact':
                return 'CONTACT';
            case 'dcim.device':
                return 'DEVICE';
            case 'circuits.circuit':
                return 'DEVICE';
            default:
                return 'UNKNOWN';
        }
    }


    const getChangeLogsData = async (pagination, searchText, filter) => {
        let filterReq = filter ? filter :  {filter: filterState?.length > 0 ?  JSON.stringify(filterState): null}
        let req = pagination ? {...pagination, searchText: searchText? searchText: searchTextState, ...filterReq} : {offset: 1, limit: 20, searchText: searchText? searchText: searchTextState,  sortField: 'date and time', sortOrder: 'desc', ...filterReq}
        await dispatch(setLoading(true));
        try{
        const changeLogsData = await dispatch(fetchChangeLogs({ tenantId: user?.tenant?.id, tenantName: user?.tenant?.name , ...req})).unwrap();
        if (changeLogsData) {
            let formattedData = formatData(changeLogsData?.data);
            setTotalCount(changeLogsData?.count);
            await setFilteredData(formattedData);
        } else {
            await setFilteredData([]);
        }
    } catch (error) {
        toast.error("Oops! Something went wrong");
        console.error("Error fetching tenants data:", error);
    } finally {
        await dispatch(setLoading(false));
    }
    };

    const handleSearch = async (e) => {
        if (e.target.value) {
            setSearchTextState(e.target.value)
            await getChangeLogsData(null, e.target.value, {filter: filterState?.length > 0 ?  JSON.stringify(filterState): null});
        } else {
            setSearchTextState(null)
            await getChangeLogsData(null, e.target.value, {filter: filterState?.length > 0 ?  JSON.stringify(filterState): null});
        }
    };

    createTheme('table__dark__theme',
        ReactDataTableStyles
    );

    return (
        <>
            <MuiThemedBox className="main_inner_wrap changelogs__list">
                <div style={{ display: 'flex' }}>
                    <Breadcrumbs text={"Change Logs"} />
                    <div className='flapping__header__text'>(Last 7 days data)</div>
                </div>
                <Grid container className="flex_wrap justify-between">
                    <Grid item md={3} className="filter_wrap">
                        <SearchInputField handleClick={handleSearch} />
                    </Grid>
                    <Grid item md={5.8} className="flex flex_Wrap filter" >
                        <DateRangeLayout
                            className="date_range"
                            setFilterDate = {setselectedDate}
                            selectedDate = {selectedDate}
                            onChange={(date) => handleDateChange(date)}
                        />
                        <ThemedReactSelect
                            onChange={(val) => {
                                filterData(val);
                            }}
                            value={changeLogsType}
                            options={changeLogsTypeList}
                            placeholder={"ChangeLogs type"}
                            components={{ Control }}
                            className="service_filter"
                            isClearable={true}
                         
                        />
                    </Grid>
                </Grid>
                <Box>
                <TableComponent
                        className="rdt_table_wrap"
                        columns={filterColumns(tableColumns)}
                        rows={filteredData}
                        customStyles={customStyles}
                        isLoading={user?.isLoading}
                        onPageChange = {getChangeLogsData}
                        defaultSortField = {'date and time'}
                        defaultSortOrder = {'desc'}
					totalRows = {totalCount}
                    />
                </Box>
            </MuiThemedBox>

        </>
    );
}

export default ChangeLogs;
