import React, {useEffect, useState} from 'react';
import TableComponent from 'components/common/TableComponent/TableComponent';
import Box from "@mui/material/Box";
import Breadcrumbs from "components/common/Breadcrumps";
import { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel';
import SelectDropDown from 'components/common/SelectDropdown';
import {useSelector, useDispatch} from 'react-redux';
import MinusIcon from "assets/icons/minus.svg";
import PlusIcon from "assets/icons/plus.svg";
import { fetchFlappedSiteNDevices, updateRecord } from 'store/eventLogs';
import FlappingDevice from './FlappingDevice';
import DateRangeLayout from "components/common/DateRange";
import moment from "moment";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './flappingLogs.css';
import Tooltip from "@mui/material/Tooltip";
import ConfirmationPopup from 'components/common/ConfirmationPopup/ConfirmationPopup';
import GlobalExportButton from 'components/common/GlobalExportButton';
import { toast } from 'react-toastify';

export default function FlappingLogs() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const tenants = useSelector((state) => state.devices?.tenants);
    const[flappingSite, setFlappingSite] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tenantFilter, setTenantFilter] = useState([]);
    const [totalRow, setTotalRow] = useState(0);
    const [paginationState, setPaginationState] = useState({offset: 1, limit: 20})
    const [dateFilterState, setDateFilterState] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        setTenantFilter([]);
        setFlappingSite([]);
        if (user?.tenant?.id){
            getFlappingSiteData();
        }
    }, [user?.tenant]);


    const handleDateChange = async (date) => {
        let value = null ;
        if(date) {
        value = {
            start: moment(date.startDate).format("X") * 1000,
            end: moment(date.endDate).format("X") * 1000,
        };
    }
        setDateFilterState(value);

        let filter =  {dateFilter: JSON.stringify(value)}

        getFlappingSiteData(null, filter, tenantFilter);
    };

    const onChangeTenant = async(val) => {
        setTenantFilter(val);
        getFlappingSiteData(null, null, val);
    }

    const getFilterRequest = (tenantId, pagination, dateFilter) =>{
        let id = tenantId ? tenantId?.value : tenantFilter?.value;
        let meraki_org_id = tenantId ? tenantId?.custom_fields?.meraki_org_id :  user?.tenant?.custom_fields?.meraki_org_id;
        let paginationValue = pagination ? pagination : paginationState;
        let dateFilterValue = dateFilter ? dateFilter : {dateFilter : JSON.stringify(dateFilterState)}
        let request = { 
            tenantId: user?.tenant?.name !== 'Sangoma'?  user?.tenant?.id : id, 
            meraki_org_id : meraki_org_id,
            ...paginationValue, 
            ...dateFilterValue, 
            userId: user.id 
        }
        return request || {}

    }

    const getFlappingSiteData = async(pagination, dateFilter, tenant) => {
        setIsLoading(true);
        const tenantId = tenant ? tenant?.value : null;
        if(pagination) setPaginationState(pagination);

        const request = getFilterRequest(tenant, pagination, dateFilter)
        try{
        const data = await dispatch(fetchFlappedSiteNDevices(request)).unwrap();
        let formatData = data?.data?.map(i => {
            if(i.devices.some(obj => obj.is_new_log)){
                i['is_new_log'] = true;
            }
            i['tenantId'] = user?.tenant?.name !== 'Sangoma'? user?.tenant?.id : tenantId
            return i
        })
        setFlappingSite(formatData);
        setTotalRow(data?.count)
    }catch (error) {
        toast.error("Oops! Something went wrong");
        console.error("Error fetching tenants data:", error);
        // Handle the error appropriately, such as showing an error message to the user
    } finally {
        setIsLoading(false); // End loading state
    }
    }

    const updateLogState = async(deviceData, siteId) => {
       const cloneData = [...flappingSite];
       let siteToUpdate = cloneData.find(i => i.site_id === siteId);
       siteToUpdate.devices = deviceData;
       if(!siteToUpdate.devices.some(obj => obj.is_new_log)){
           siteToUpdate['is_new_log'] = false;
        }       
       setFlappingSite(cloneData);
    }

    const onMarkAllReadClick = () => {
        setOpenPopup(true);
    }

    const onClosePopup = () => {
        setOpenPopup(false);
    }

    const markAllRead = async () => {
        try {
            setShowLoader(true)
            const req = {
                userId: user?.id, markAllRead: true, tenantId: user?.tenant?.name !== 'Sangoma' ?
                    user?.tenant?.id : tenantFilter.value
            };
            await dispatch(updateRecord(req));
           
        } catch (error) {
            console.error(error);
            toast.error("Oops! Something went wrong");
        } finally {
            setOpenPopup(false);
            setShowLoader(false);
            await getFlappingSiteData(null, null, user?.tenant?.name !== 'Sangoma' ?
                user?.tenant?.id : tenantFilter)
        }
    }
    const confirmPopupProps = {
        heading: "Flapping Logs",
        text: "Are you sure you want to mark all logs as read?",
        onClose: onClosePopup,
        onConfirm: markAllRead,
        confirmText: 'OK',
        closeText: 'Close',
        onConfirmLoader: showLoader
    }

  return (
    <Box className="main_inner_wrap">
            <div style={{display: 'flex'}}>
            <Breadcrumbs text={`Flapping Logs `} />
            <div className='flapping__header__text'>(Last 1 Month)</div>
            </div> 
            <Box className= "flapped_link_heading" pt={2} pb={2} style={{ marginLeft: '-15px' }}>
            <div>
                 {user?.tenant?.name === 'Sangoma' &&  
                        <SelectDropDown value={tenantFilter} styles={customTenantStyle} options={tenants?.filter(i => i.name !== 'Sangoma')?.map(i => {
                            return {
                                value: i.id,
                                label: i.name,
                                custom_fields: i.custom_fields
                            }
                        })} placeholder={'Select Tenant'} hideFilterIcon={true} onChange={onChangeTenant} />}
                
            </div>
            <Box className='flex'>
                {(user?.tenant?.name !== 'Sangoma'|| (user?.tenant?.name === 'Sangoma'&& tenantFilter.value !== undefined)) &&
                     <>
                  <DateRangeLayout className="date_range" onChange={(date) => handleDateChange(date)} />
                  <Button onClick={onMarkAllReadClick} variant="outlined" size="small" color="success" style={{marginLeft:'10px'}}>
                  <FontAwesomeIcon icon="fa-solid fa-circle-check" className="export_btn" style={{margin:'3px 5px 3px 3px'}}/>
                    Mark all read
                  </Button>
                      <GlobalExportButton 
                                                enableFilteredDataExportButton={

                                                    (dateFilterState && Object.keys(dateFilterState)?.length > 0 &&
                                                        !Object.values(dateFilterState)?.every(value => !value)
                                                    )
                                                }exportApiRequest={{
                        tenantId: user.tenant?.id ?  user.tenant?.id : tenantFilter.value,
                        filetype:'csv',
                        dataOf:'events',
                        ...getFilterRequest(null, {offset: 1, limit: totalRow})
                      }}
                          heading={`Flapping Logs`}
                          text={"Are you sure you want to download flapping logs list ?"}
                          fileName={"Events"}
                      />
                        </>
                }</Box>       
            </Box>
                <TableComponent
                    className="rdt_table_wrap"
                    columns={columns}
                    rows={flappingSite}
                    isLoading={isLoading}
                    expandableRows = {true}
                    expandableRowsComponentProps={{user: user, tenantId : user?.tenant?.id || tenantFilter?.value,
                         dateFilter : JSON.stringify(dateFilterState), updateLogState: updateLogState}}
                    expandableIcon={{
						collapsed: (
							<img src={PlusIcon} alt="open" className="expand__icon" />
						),
						expanded: (
							<img src={MinusIcon} alt="close" className="expand__icon" />
						),
					}}
                    removePreferenceCell = {true}
                    expandableRowsComponent={FlappingDevice}
                    onPageChange={getFlappingSiteData}
                    totalRows={totalRow}

                />
            {openPopup && <ConfirmationPopup {...confirmPopupProps}/>}
        </Box>
  )
}

const columns = [
    {
        cell: (row) => <SiteComponent row = {row}/>,
        name: "Site Display Name",
        dataType: "String",
        weight: 1,
        minWidth: '50px',
        isEnabled: true,
    }
]

const customTenantStyle = {
    container: (base) => ({
        ...base,
        marginLeft: '15px',
        width: '210px',
        marginRight: '15px',
    })
}

const SiteComponent = ({row}) => {
    const [isRead, setIsRead] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [openPopup, setOpenPopup] = useState(false)

    const onMarkRead = async() => {
        setIsRead(true)
        let deviceIds = row?.devices?.map(i => {
            i.is_new_log = false;
            return i.netbox_id
        });
        deviceIds = JSON.stringify(deviceIds);
        const req = {userId : user?.id, deviceIds : deviceIds, tenantId : row?.tenantId};
        setOpenPopup(false);
        await dispatch(updateRecord(req)); 
    }

    const onMarkAllReadClick = () => {
        setOpenPopup(true);
    }

    const onClosePopup = () => {
        setOpenPopup(false);
    }


    const confirmPopupProps = {
        heading: "Flapping Logs",
        text: "Are you sure you want to mark site logs as read?",
        onClose: onClosePopup,
        onConfirm: onMarkRead,
        confirmText: 'OK',
        closeText: 'Close'
    }

    return (
        <div className='flapped_site_name_wrap'>
            <div style={{width:`calc(100% - 50px)`,display:"flex",  alignItems:"center", justifyContent:"flex-start"}}>
                <StyledTooltip title={`Site Name: ${row?.site_name}`} marginLeft="45px">
                    <span>{row?.site_display_name ? row?.site_display_name : row?.site_name ? row?.site_name : '--'}</span>
                </StyledTooltip>
                {row?.is_new_log && !isRead && <div className='flapped_dot'>New Logs</div>}
            </div>
            <div style={{width:"50px", display:"flex", alignItems:"center", justifyContent:"center"}}>            
                {row?.is_new_log && !isRead &&
                <Tooltip title={'Mark Read'} >
                    <FontAwesomeIcon icon="fa-solid fa-circle-check" className='flapped_check_icon' onClick={onMarkAllReadClick} />
                </Tooltip>
                }

            </div>

            {openPopup && <ConfirmationPopup {...confirmPopupProps} />}
        </div>
    )
}


