/* eslint-disable no-unsafe-optional-chaining */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Breadcrumbs from 'components/common/Breadcrumps'
import ButtonLayout from 'components/common/Button'
import { MuiThemedBox, MuiThemedButton, MuiThemedCircularProgress, MuiTypography } from 'components/common/MuiThemedComponents'
import FormPopup from 'components/common/modal/FormPopup'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addTicketComments, fetchTicketById } from 'store/tickets'
import AddCommentWorkNotes from './AddCommentPopup'
import CommentComponent from './CommentComponent'
import DescriptionComponent from './DescriptionComponent'
import PriorityComponent from './PriorityComponent'
import TicketStatusComponent from './TicketStatusComponent'
import { cleanString, ticketSalesForceSideBarDetails, ticketServiceNowSideBarDetails, typeMapper } from './ticketsConstants'
import { useCanAccess } from 'services/canACLAccess'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel'

export default function TicketDetail(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const canAccess = useCanAccess();
  const user = useSelector((state) => state?.user);
  const ticketsSelector = useSelector((state) => state?.tickets);
  const { ticketDetails } = ticketsSelector
  const {
    ticketingSystem = null,
    tenantId = null,
    ticketId = null,
    device_id = null,
    service = null,
    previousScreen = null,
    caseNumber = null,
    ticketType = null
  } = ticketDetails || {};
  const createdByText = `\nAdded By: ${user?.name} (NetOps)\nEmail: ${user?.email}`;
  const [isLoading, setIsLoading] = useState(false);
  const [ticketDetail, setTicketDetail] = useState(null);
  const [addingWorkNotes, setAddingWorkNotes] = useState(false);
  const [addingComments, setAddingComments] = useState(false);
  const [isAddingComments, setIsAddingComments] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const commentAccordionDetails = useMemo(() => {
    return [
      {
        id: 1,
        valueKey: "work_notes",
        title: "Work Notes (Private)",
        type: "Comment",
        isEnabled: user?.tenant?.name == "Sangoma"
      },
      {
        id: 2,
        valueKey: "comments",
        title: "Comments",
        type: "Comment",
        isEnabled: true
      },
    ]
  }, [user?.tenant?.name, user?.userRole])

  const formatSubject = useCallback((subject) => {
    if (user?.tenant?.name === "Sangoma") {
      return subject
    } else {
      const newSubject = subject && subject?.length > 0 && subject?.split("|")?.slice(1)?.join("|") || "--";
      return newSubject
    }
  }, [user?.tenant?.name])

  const getSidebarDetails = useCallback((showMoreValue) => {
    let sidebarDetails = [];

    if (ticketingSystem === "salesforce") {
      sidebarDetails = ticketSalesForceSideBarDetails;
    } else if (ticketingSystem === "servicenow") {
      sidebarDetails = ticketServiceNowSideBarDetails;
    }

    if (!ticketDetail || sidebarDetails?.length === 0) {
      return [];
    }

    const filteredDetails = sidebarDetails?.reduce((filtered, detail) => {
      const value = getValue(detail?.valueKey, detail?.type);
      if (value !== null && value !== undefined) {
        filtered.push({
          ...detail,
          value
        });
      }
      return filtered;
    }, []);
    if (filteredDetails && filteredDetails?.length > 0) {
      if (showMoreValue) {
        return filteredDetails
      } else {
        return filteredDetails?.slice(0, 5);
      }
    }
  }, [ticketDetail, ticketingSystem, ticketSalesForceSideBarDetails, ticketServiceNowSideBarDetails]);

  const handleAddNotes = useCallback((valueKey, value) => {
    if (valueKey === "comments") {
      setAddingComments(value)
    } else if (valueKey === "work_notes") {
      setAddingWorkNotes(value)
    }
  }, [])

  // REMINDER: It has to be uncommented for PHASE 2 RELEASE
  // eslint-disable-next-line no-unused-vars
  const enableAddCommentsButton = useCallback((valueKey) => {
    if (canAccess('update', 'tickets')) {
      if (valueKey === "comments") {
        return true
      } else if (valueKey === "work_notes" && (user?.tenant?.name === "Sangoma" || user?.userRole === 'admin')) {
        return true
      }
    }
    return false
  }, [])

  const onhandleSubmitOfComments = useCallback(async (value, valueKey) => {
    setIsAddingComments(true);
    try {
      let requestPayload = {
        "id": ticketId,
        "comment": value + createdByText,
        "type": typeMapper[valueKey],
        "ticketingSystem": ticketingSystem,
      }
      const response = await dispatch(addTicketComments(requestPayload));
      if (response?.payload?.success) {
        toast.success(`${valueKey === "comments" ? "Comment" : "Work Note"} added successfully`);
        handleAddNotes(valueKey, false)
        fetchTicketData()
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsAddingComments(false)
    }
  }, [ticketId])


  const fetchTicketData = useCallback(async () => {
    setIsLoading(true)
    const req = {
      ticketId: ticketId,
      params: {
        type: ticketingSystem,
        tenantId: tenantId
      }
    }
    await dispatch(fetchTicketById(req))?.unwrap().then(async (response) => {
      if (response && Object.keys(response)?.length > 0) {
        let ticket = {}
        if (ticketingSystem === "salesforce") {
          ticket = {
            subject: response?.Subject,
            description: response?.Description,
            priority: response?.Priority,
            ...response
          }
        } else if (ticketingSystem === "servicenow") {
          let detail = response?.result;
          ticket = {
            subject: detail?.short_description,
            description: detail?.description,
            ...detail
          }
        }
        setTicketDetail(ticket);
      }
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setIsLoading(false);
    })
  }, [ticketingSystem, ticketId]);

  const getValue = useCallback((valueKey, type) => {
    const retrieveNestedValue = (obj, key) => {
      return key && key?.split('.').reduce((acc, part) => acc && acc[part], obj);
    };
    const retrievedValue = ticketDetail && retrieveNestedValue(ticketDetail, valueKey);

    if (type === "Date") {
      const parsedDate = Date.parse(retrievedValue);
      if (retrievedValue && !isNaN(parsedDate)) {
        return moment(parsedDate).format("DD-MM-YYYY") + " " + moment(parsedDate).format("LT");
      } else {
        return "--";
      }
    } else if (type === "Status") {
      return <TicketStatusComponent status={retrievedValue} />;
    } else if (type === "CleanString") {
      return retrievedValue ? cleanString(retrievedValue) : "--";
    } else if (type === "Priority") {
      return <PriorityComponent priority={retrievedValue} ticketingSystem={ticketingSystem} />;
    } else if (type === "Ticket Source") {
      return retrievedValue ? retrievedValue : "NetOps";
    }
    if (valueKey === "description" && ticketType == "external") {
      return retrievedValue
    }
    return retrievedValue ? retrievedValue : "--";
  }, [ticketDetail, ticketType]);

  const redirectToCase = useCallback((ticketId) => {
    const redirectUrl = process.env.REACT_APP_SALESFORCE_REDIRECT_URL + ticketId + '/view';
    window.open(redirectUrl, '_blank');
  }, [])

  const redirectToLinkMonitoring = useCallback((deviceName, ipAddress) => {
    navigate("/nf-status/link-monitoring", {
      state: {
        device_id,
        service,
        ticketId,
        deviceName,
        ipAddress
      }
    });
  }, [navigate, device_id, service, ticketId]);

  useEffect(() => {

    // REMINDER: TenantId received from tickets table should match the users tenantId else user should belong to sangoma
    if ((user?.tenant?.id == tenantId || user?.tenant?.name === 'Sangoma') ) {
      fetchTicketData()
    } else {
      setTicketDetail(null)
      props?.back()
    }
  }, [user?.tenant?.id, tenantId])

  return (
    <MuiThemedBox className={"main_inner_wrap ticket__detail__dashboard"} >
      <Box >
        <Breadcrumbs text={previousScreen || 'tickets'} backAction = {props?.back} secondaryText={caseNumber} />
      </Box>

      <Box className="ticket__container" >
        {isLoading ? (<MuiThemedBox className="loader__wrapper shadow">
          <MuiThemedCircularProgress themePreference={user?.themePreference} />
        </MuiThemedBox>)
          :
          !ticketDetail || ticketDetail && Object.keys(ticketDetail).length === 0 ?
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '400px' }} >
              There are no details to display
            </Box>

            :
            <Grid container md={12} xs={12} lg={12}  >
              <Grid item md={8} lg={8} xs={12} sx={{ display: "flex" }}>
                { /**Ticket Left Section*/}
                <MuiThemedBox className="ticket__detail__right__wrap shadow">
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }} className="ticket__detail__right__wrap--header">
                    <Typography component='div' className="subject">{ticketType === "external" ? getValue("subject") : formatSubject(getValue("subject"))}</Typography>

                    <Box className="redirect__btn__wrap" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", paddingLeft: "16px" }}>

                      <MuiThemedButton
                        sx={{ fontSize: "14px", padding: "8px 10px", maxHeight: "34px", textTransform: "uppercase", letterSpacing: "1.5px", fontWeight: 600 }}
                        onClick={() => { if (getValue("ticket_id")) { redirectToCase(getValue("ticket_id")) } }}
                        variant={"contained"}
                        endIcon={<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square " style={{ fontSize: "16px" }} />}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "2px" }}>
                          Salesforce
                        </Box>
                      </MuiThemedButton>
                    </Box>

                  </Box>
                  <Box className="ticket__detail__right__wrap--content">
                    { /**Description */}
                    <DescriptionComponent value={getValue("description")} ticketType={ticketType} redirectToLinkMonitoring={redirectToLinkMonitoring} />
                    { /**Comment */}
                    {commentAccordionDetails && commentAccordionDetails?.length > 0 && commentAccordionDetails?.map((commentDetail, idx) => {
                      if (commentDetail?.isEnabled) {
                        const content = getValue(commentDetail.valueKey, commentDetail.type);
                        return (
                          <Accordion className='ticket__detail__right__wrap--accordion' key={idx} defaultExpanded={Array.isArray(content) && content?.length === 0 ? false : true}>
                            <AccordionSummary
                              expandIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-down" />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component='div'>
                                  {commentDetail.title}
                                </Typography>
                              </Box>

                            </AccordionSummary>
                            <AccordionDetails>
                              {(Array.isArray(content) && content?.length > 0) ?
                                (
                                  <Box sx={{ maxHeight: "400px", overflowY: "auto", padding: "16px" }}>
                                    {Array.isArray(content) && ticketingSystem === "salesforce" ?
                                      content && content?.length > 0 && content?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) && content?.map((element, index) => {
                                        return (
                                          <CommentComponent ticketType={ticketType} ticketId={ticketId} ticketingSystem={ticketingSystem} redirectToLinkMonitoring={redirectToLinkMonitoring} key={index} element={element} valueKey={commentDetail.valueKey} />
                                        )

                                      }) :
                                      (<Typography component='div'>
                                        {Array.isArray(content) ? content : content}
                                      </Typography>)}
                                  </Box>
                                ) : <Typography component='div' sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "20px" }}>
                                  {commentDetail.valueKey === "comments" ? "No Comments Found" : "No Work Notes Found"}
                                </Typography>}

                              {ticketingSystem === "salesforce" && enableAddCommentsButton(commentDetail?.valueKey) &&
                                <Box sx={{ padding: "16px", position: "sticky", borderTop: "1px solid #D8D9DA", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                  <ButtonLayout buttonStyleType="filled"
                                    isLoading={false}
                                    handleClick={() => { handleAddNotes(commentDetail?.valueKey, true) }}
                                    type="submit" text={commentDetail?.valueKey === "comments" ? 'Add Comment' : 'Add Work Notes'}>
                                  </ButtonLayout>
                                </Box>
                              }
                            </AccordionDetails>
                          </Accordion>
                        )
                      }

                    })}

                  </Box>
                </MuiThemedBox>
              </Grid>

              <Grid item md={4} lg={4} xs={12} sx={{ paddingLeft: "16px" }}>
                { /**Ticket Right Section*/}
                <MuiThemedBox className="ticket__detail__left__wrap shadow">
                  <Box className="ticket__detail__left__wrap--content">
                    {getSidebarDetails(showMore) && getSidebarDetails(showMore).length > 0 && getSidebarDetails(showMore).filter(i => i.isEnabled).map((detail, idx) => {
                      return (
                        <MuiThemedBox key={idx} className='ticket__sidebar__card'>
                          <MuiTypography className='ticket__sidebar__card--header'>
                            {detail?.title}:
                          </MuiTypography>
                          &nbsp; &nbsp;
                          <Typography component='div' className='ticket__sidebar__card--value'>
                            <TooltipLabel fontStyle={{ fontSize: "14px" }} text={getValue(detail?.valueKey, detail?.type)} />
                          </Typography>
                        </MuiThemedBox>
                      )
                    })}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} onClick={() => setShowMore(!showMore)} className='ticket_show_more'>
                    {showMore ? 'Show less' : 'Show more'}
                  </Box>
                </MuiThemedBox>
              </Grid>
            </Grid>
        }
      </Box>
      {addingComments &&
        <FormPopup open={addingComments} size={"create__comment__popup__modal "} className="">
          <AddCommentWorkNotes
            text={"Add Comments"}
            onClose={() => setAddingComments(false)}
            isLoading={isAddingComments}
            onhandleSubmitOfComments={onhandleSubmitOfComments}
            title={"Comments"}
            placeholder={""}
            identifier={"comments"}
          />
        </FormPopup>
      }

      {addingWorkNotes &&
        <FormPopup open={addingWorkNotes} size={" create__comment__popup__modal"} className="">
          <AddCommentWorkNotes
            text={"Add WorkNote"}
            onClose={() => setAddingWorkNotes(false)}
            isLoading={isAddingComments}
            onhandleSubmitOfComments={onhandleSubmitOfComments}
            title={"WorkNotes"}
            placeholder={""}
            identifier={"work_notes"}
          />
        </FormPopup>
      }
    </MuiThemedBox>
  )
}

