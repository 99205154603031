import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid } from '@mui/material'
import Breadcrumbs from 'components/common/Breadcrumps'
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell'
import GlobalExportButton from 'components/common/GlobalExportButton'
import { MuiThemedBox } from 'components/common/MuiThemedComponents'
import MultiSelectDropDown from 'components/common/MultiSelectDropDown'
import SearchInputField from 'components/common/SearchInputField'
import SelectDropDown from 'components/common/SelectDropdown'
import TableComponent from 'components/common/TableComponent/TableComponent'
import TableHeader from 'components/common/TableHeader/TableHeader'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel'
import Timer from 'components/component/link/Timer'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router"
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchAllTickets, fetchSalesForceReferenceStatus, fetchTicketingSystems, resetTickets, setTicketDetails } from 'store/tickets'
import PriorityComponent from './PriorityComponent'
import TicketStatusComponent from './TicketStatusComponent'
import { SalesforceTicketStatusOptionsList, ServiceNowTicketStatusOptionsList, ticketCardConstants, ticketDetails } from './ticketsConstants'
import ButtonLayout from 'components/common/Button'
import TicketDetail from './TicketDetail'

// Permissions
// create, read, update, delete

export const ticketingSystems = [
  {
    id: 1,
    value: 'Salesforce',
    label: 'Salesforce'
  },
  {
    id: 2,
    value: 'Servicenow',
    label: 'Servicenow'
  }
]

export const ticketTypeOptions = [
  {
    id: 1,
    value: 'internal',
    label: 'Internal'
  },
  {
    id: 2,
    value: 'external',
    label: 'External'
  }
]

export default function TicketsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state?.user);
  const tenants = useSelector((state) => state.devices?.tenants);
  const ticketsSelector = useSelector((state) => state?.tickets);
  const { allTickets, totalTicketCount, ticketStatus } = ticketsSelector;
  const [openTicketDetailsPage, setOpenTicketDetailsPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState([{ key: 'type', value: ['external'] }]);
  const [searchInputValue, setSearchInputValue] = useState();
  const [tenantFilter, setTenantFilter] = useState([]);
  const [ticketStatusFilter, setTicketStatusFilter] = useState([]);
  const [ticketingStatusOptionsList, setTicketingStatusOptionsList] = useState([]);
  const [ticketingSystemOptionsList, setTicketingSystemOptionsList] = useState([]);
  const [showDescriptionPage, setShowDescriptionPage] = useState(false);
  const [ticketingSystemFilter, setTicketingSystemFilter] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [ticketingTypeFilter, setTicketingTypeFilter] = useState([{ id: 2, value: 'external', label: 'External' }]);
  const [tenantOptionsList, setTenantOptionsList] = useState([]);
  const [highlightFilter, setHighLightFilter] = useState(false);
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [showTicketCardLoader, setShowTicketCardLoader] = useState(false);
  const [showTicketStatusLoader, setShowTicketStatusLoader] = useState(false);
  const [tableRefreshKey, setTableRefreshKey] = useState(null);
  const [paginationState, setPaginationState] = useState({
    offset: 1, limit: 20, sortField: 'updated_at', sortOrder: 'desc'
  }) 

  const setPaginationData = (newState) => {
    setPaginationState(prevState => ({ ...prevState, ...newState }))
  }

  const getCurrentTenant = useCallback((tenant_id) => {
    const currentTenant = tenants && tenants?.length > 0 && tenants.find(tenant => tenant?.id == tenant_id)?.display;
    return currentTenant
  }, [])

  const getTicketingSystems = useCallback(async () => {
    try {
      const tenantId = user?.tenant?.name === "Sangoma" ? "sangoma" : user?.tenant?.id;
      const request = { tenantId };

      const response = await dispatch(fetchTicketingSystems(request))?.unwrap();

      if (Array.isArray(response) && response.length) {
        const options = response.map(item => ({
          id: item?.id,
          label: item?.name,
          value: item?.name
        }));

        setTicketingSystemOptionsList(options);
        const salesforceOption = options.find(item => item.value === "Salesforce") ||
          ticketingSystems?.find(item => item.value === "Salesforce");
        if (salesforceOption) {
          setTicketingSystemFilter(salesforceOption);
          return salesforceOption.value.toLowerCase();
        }
      } else {
        setTicketingSystemOptionsList([]);
        setTicketingSystemFilter(ticketingSystems?.find(item => item.value === "Salesforce"));
        toast.error(response);
        return null;
      }
    } catch (error) {
      console.error("Error fetching ticketing systems:", error);
      toast.error("Failed to fetch ticketing systems.");
      return null;
    }
  }, [user?.tenant?.id, dispatch, ticketingSystems]);

  const enableTicketingSystemFilter = useCallback(() => {
    // if (ticketingSystemOptionsList?.length > 1) {
    //   return true
    // } else if (user?.tenant?.name === "Sangoma") {
    //   return true
    // }

    return false

  }, [ticketingSystemOptionsList])

  const getTicketingStatusOptions = useCallback((ticketingSystem) => {
    if (ticketingSystem === "servicenow") {
      setTicketingStatusOptionsList(ServiceNowTicketStatusOptionsList)
    } else if (ticketingSystem === "salesforce") {
      // setTicketingStatusOptionsList(SalesforceTicketStatusOptionsList)
    }
  }, [ServiceNowTicketStatusOptionsList, SalesforceTicketStatusOptionsList])

  // eslint-disable-next-line no-unused-vars
  const getFilterRequest = useCallback((pagination, recentlyopen, filters, searchText, ticketingSystem, ticketingSystemOption) => {
    const paginationRequest = pagination ? { ...pagination } : { ...paginationState };
    const request = {
      recentlyopen: recentlyopen || location?.state?.status === "recently_open",
      filter: filters ? filters : searchInputValue || searchText  ? [] : filterValue ? JSON.stringify(filterValue) : null,
      searchText: searchText === '' ? searchText : searchText ? searchText : searchInputValue?.length > 0 ? searchInputValue : null,
      ticketing_system: 'salesforce',
      // ticketingSystem
      //   ? ticketingSystem?.toLowerCase()
      //   : ticketingSystemFilter?.value?.toLowerCase()
      //     ? ticketingSystemFilter?.value?.toLowerCase()
      //     : ticketingSystemOption,
      ...paginationRequest,
    };

    const req = {
      params: request,
      tenantId: user?.tenant?.name === "Sangoma" ? "sangoma" : user?.tenant?.id,
    };    
    return req;

  }, [paginationState, location, filterValue, searchInputValue, ticketingSystemFilter, user?.tenant?.id, user?.tenant?.name])

  const getTicketsData = useCallback(
    async (pagination, searchText, filters, ticketingSystem, callTicketingSystemsApi, recentlyopen) => {
      setIsLoading(true);
      try {
        const ticketingSystemOption = callTicketingSystemsApi ? await getTicketingSystems() : null;
        if ((callTicketingSystemsApi && ticketingSystemOption)) {
          await getTicketingStatusOptions(ticketingSystemOption);
        }
        if(!pagination && !searchText) {
          setShowTicketCardLoader(true)
        }

        if (pagination) {
          setPaginationData({
            offset: pagination?.offset,
            limit: Number(pagination?.limit),
            sortField: pagination?.sortField,
            sortOrder: pagination?.sortOrder,
          });
        }

        const req = getFilterRequest(pagination, recentlyopen, filters, searchText, ticketingSystem, ticketingSystemOption);
        await dispatch(fetchAllTickets(req));
      } catch (error) {
        console.error('Error fetching ticket data:', error);
      } finally {
        setIsLoading(false);
        setShowTicketCardLoader(false);
      }

    }, [user?.tenant?.id, user?.tenant?.name, searchInputValue, filterValue, ticketingSystemFilter, getFilterRequest]);

  const handleSearch = useCallback(async (e) => {
    setSearchInputValue(e.target.value);
    setTableRefreshKey(new Date().getTime());
    await getTicketsData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, e.target.value, null);
  }, [filterValue, ticketingSystemFilter])

  const updateFilter = useCallback(async (key, val, filterType) => {
    if (location.state) {
      navigate(location.pathname, { state: null })
		}
    let cloneFilter = [...filterValue];
    if (key === "ticketing_system") {
      if (val?.id !== ticketingSystemFilter?.id) {
        const updatedFilterValue = filterValue?.filter(i => i.key !== "status");
        await getTicketsData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, '', JSON.stringify(updatedFilterValue), val?.value);
        await getTicketingStatusOptions(val?.value?.toLowerCase())
      }
      setTicketStatusFilter([]);
    }
    else {
      const filter = val ? [] : [...filterType];
      
      const value = filter.some(obj => obj.value?.toString()?.toLowerCase() === ('all')) ? ['all'] : filter?.map(i => i.value?.toString());
      cloneFilter = cloneFilter?.filter(i => i.key !== key);
      if (value?.length > 0) {
        cloneFilter.push({ value: value, key: key });
      }
      setFilterValue(cloneFilter);
      const filterReq = cloneFilter?.length > 0 ? JSON.stringify(cloneFilter) : [];
      getTicketsData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, null, filterReq, null);
    }

  }, [filterValue, ticketingSystemFilter, SalesforceTicketStatusOptionsList, getTicketingStatusOptions, ServiceNowTicketStatusOptionsList]);

  const onApplyFilter = useCallback((key, val) => {
			setSearchInputValue('')
      setTableRefreshKey(new Date().getTime())
    switch (key) {
      case 'Ticket Status':
        updateFilter('status', val, ticketStatusFilter);
        break;
      case 'Tenants':
        updateFilter('tenant_id', val, tenantFilter,);
        break;
      case 'Ticketing System':
        updateFilter('ticketing_system', val, ticketingSystemFilter);
        break
      case 'Ticket Type':
        updateFilter('type', null, val);
        break;
      default:
        break
    }
  }, [ticketStatusFilter, tenantFilter, ticketingSystemFilter, filterValue, ticketingStatusOptionsList])

  const handleTicketNumberClick = useCallback((row) => {
    const ticketingSystem = ticketingSystemFilter?.value?.toLowerCase();

    const currentTenant = tenants && tenants?.length > 0 && tenants.find(tenant => tenant.id == row?.tenant_id)?.display;
    setOpenTicketDetailsPage(!openTicketDetailsPage);
    dispatch(setTicketDetails({
      ticketId: row?.id,
      ticketType: row?.type,
      caseNumber: row?.case_number,
      tenantName: currentTenant,
      tenantId: row?.tenant_id,
      previousScreen: "Tickets",
      previousScreenURL: "tickets",
      ticketingSystem: ticketingSystem,
      device_id: row?.device_id,
      service: row?.service
    }))
    setShowDescriptionPage(true);
  }, [tenants, ticketingSystemFilter, ticketStatusFilter, tenantFilter, paginationState])

  const ticketColumns = useMemo(() => {
    const enableSiteColumns = ticketingTypeFilter && ticketingTypeFilter?.length > 0 && ticketingTypeFilter?.find(item => item?.value == "internal") || false

    const tenantColumn = [
      {
        name: "Tenant Name",
        title: "Tenant Name",
        dataType: "String",
        isEnabled: user?.tenant?.name === "Sangoma",
        minWidth: '100px',
        selector: (row) => {
          const tenantValue = getCurrentTenant(row?.tenant_id);
          return (
            <TooltipLabel text={tenantValue ? tenantValue : "-"} />
          )
        },
      },
    ]
    const siteAndTenantColumns = [
      {
        name: "Site Display Name",
        title: "Site Display Name",
        dataType: "String",
        isEnabled: enableSiteColumns,
        minWidth: 150,
        wrap: true,
        cell: (row) => {
          return (
            <CopyTextCell text={row?.site_display_name ? row?.site_display_name : row?.site_name ? row?.site_name : "-"} />
          )
        },
      },
      {
        name: "Site Name",
        title: "Site Name",
        dataType: "String",
        isEnabled: enableSiteColumns,
        minWidth: 150,
        wrap: true,
        cell: (row) => {
          return (
            <CopyTextCell text={row?.site_name ? row?.site_name : "-"} />
          )
        },
      },
    ]

    const defaultColumns = [
      {
        name: "Ticket Number",
        title: "Ticket Number",
        dataType: "String",
        isEnabled: true,
        sortable: false,
        minWidth: 100,
        sortField: 'case_number',
        cell: (row) => {
          return (
            <Box className='tickets__id__cell' onClick={(e) => {
              e?.stopPropagation();
              handleTicketNumberClick(row)
            }}>
              <TooltipLabel text={row?.case_number ? row.case_number : "-"} />
              {!row?.is_ticket_viewed &&
                <Box>
                  <FontAwesomeIcon style={{ paddingLeft: "4px", color: "#FFAA71", fontSize: "16px" }} icon="fa-solid fa-bell" />
                </Box>
              }
            </Box>
          )
        }
      },
      {
        name: "Subject",
        title: "Subject",
        dataType: "String",
        isEnabled: true,
        minWidth: 200,
        wrap: true,
        cell: (row) => {
          const formattedSubject = row?.subject?.split("|")?.slice(1)?.join("|") || row?.subject;
          return (
            <TooltipLabel text={formattedSubject ? formattedSubject : "--"} />
          )
        },
      },
      {
        name: "Priority",
        title: "Priority",
        dataType: "String",
        sortable: true,
        isEnabled: true,
        minWidth: 50,
        wrap: true,
        cell: (row) => { return <PriorityComponent priority={row?.priority} ticketingSystem={ticketingSystemFilter?.value?.toLowerCase()} /> }
      },
      {
        name: "Status",
        title: "Status",
        dataType: "String",
        sortable: true,
        isEnabled: true,
        minWidth: 75,
        maxWidth: "250px",
        wrap: true,
        cell: (row) => { return <TicketStatusComponent status={row?.status} /> }

      },
      {
        name: "Created At",
        title: "Created At",
        dataType: "String",
        minWidth: 150,
        isEnabled: true,
        sortable: true,
        sortField: 'created_at',
        selector: (row) => {
          const date = moment(new Date(row?.created_at)).format('LLL');
          return (
            <TooltipLabel text={date ? date : "-"} />
          )
        }
      },
      {
        name: "Since (Updated)",
        title: "Since (Updated)",
        dataType: "String",
        isEnabled: true,
        sortable: true,
        sortField: 'updated_at',
        minWidth: 150,
        selector: (row) => {
          const date = new Date(row?.updated_at).getTime();
          return (
            <Timer targetDate={date} />
          )
        }
      },
    ]
    return [
      ...tenantColumn,
      ...(enableSiteColumns ? siteAndTenantColumns : []),
      ...defaultColumns
    ];
  }, [user, tenants, ticketingSystemFilter, ticketingTypeFilter])

  const onChangeOfStatusFilter = (val, action) => {
    setTicketStatusFilter(val)
    setHighLightFilter(false)
    if (action === 'clear') {
      onApplyFilter('Ticket Status', val);
    }
  }

  const onChangeOfTenantFilter = (val, action) => {
    setTenantFilter(val);
    if (action === 'clear') {
      onApplyFilter('Tenants', val);
    }
  }

  const onChangeOfTicketingSystemFilter = (val) => {
    setTicketingSystemFilter(val);
    onApplyFilter('Ticketing System', val);
  }

  // eslint-disable-next-line no-unused-vars
  const onChangeOfTicketTypeFilter = (val) => {
    setTicketingTypeFilter([val]);
    onApplyFilter('Ticket Type', [val]);
  }

  const filterDetails = useMemo(() => {
    return [
      {
        id: 1,
        onChangeValue: onChangeOfStatusFilter,
        options: ticketingStatusOptionsList,
        placeholder: "Ticket Status",
        value: ticketStatusFilter,
        onApplyClick: onApplyFilter,
        isEnabled: true,
        highlightFilter: highlightFilter

      },
      {
        id: 2,
        onChangeValue: onChangeOfTenantFilter,
        options: tenantOptionsList,
        placeholder: "Tenants",
        value: tenantFilter,
        onApplyClick: onApplyFilter,
        isEnabled: user?.tenant?.name === "Sangoma"
      },

    ]
  }, [ticketStatusFilter, tenantFilter, tenants, ticketingStatusOptionsList, tenantOptionsList, ticketingSystemFilter, user?.tenant?.name, filterValue, highlightFilter]);

  const handleStatusValueForFiltersOnRedirection = useCallback((status, options) => {
    let filterValuex = [];
    if(filterValue?.length > 0) {
      filterValuex = filterValue
    }
    filterValuex = filterValuex.filter(i => i.key !== 'status');

    if(user?.tenant?.name !== 'Sangoma') {
      setTenantFilter([]);
      filterValuex = filterValuex.filter(i => i.key !== 'tenant_id');
    }
    if (!status || !options?.length) {
      const defaultTicketStatusValues = ["Open", "New", "In Progress", "Escalated", "Needs Followup"];
      const defaultTicketStatusOptions = options?.filter(item => defaultTicketStatusValues?.includes(item?.value));
      if(defaultTicketStatusOptions) {
        setTicketStatusFilter(defaultTicketStatusOptions)
        filterValuex = [...filterValuex, {key:'status', value: defaultTicketStatusValues}]
      }
      setFilterValue(filterValuex);
      return filterValuex;
    }
    const dontIncludeData = [
      ticketCardConstants['OPEN'],
      ticketCardConstants['ESCALATED'],
      ticketCardConstants['NEW'],
      ticketCardConstants['IN_PROGRESS']
    ];

    let selectedOptions;


    if (status === ticketCardConstants['OTHER']) {
      selectedOptions = options.filter(i => !dontIncludeData.includes(i.value));
    } else if (status === ticketCardConstants['OPEN']) {
      selectedOptions = options.filter(i => i.value === "Open" );
    } else if (status === ticketCardConstants['NEW']) {
      selectedOptions = options.filter(i =>  i.value === "New");
    }
    else if (status === ticketCardConstants['ESCALATED']) {
      selectedOptions = options.filter(i =>  i.value === "Escalated");
    }
    else if (status !== ticketCardConstants['RECENTLY_OPEN']) {
      selectedOptions = [{ value: status, label: status }];
    }

    if (selectedOptions) {
      setTicketStatusFilter(selectedOptions);
      let statusArray = filterValuex?.find(item=> item?.key === "status");
      if(statusArray) {
        statusArray.value = selectedOptions.map(i => i.value || i)
      } else {
           filterValuex.unshift({
        key: 'status',
        value: selectedOptions.map(i => i.value || i),
      });
      }
    }

    setFilterValue(filterValuex);

    return  filterValuex;
  }, [ticketingStatusOptionsList, filterValue, user?.tenant?.id])


  const onHeaderCardClick = useCallback((e, item) => {
    setSearchInputValue('');
    setTableRefreshKey(new Date().getTime())
    e?.stopPropagation();
    const selectedCardId = item?.id;
    if (selectedCardId && selectedCardId !== "recently_open") {
      setHighLightFilter(true)
      const filters = handleStatusValueForFiltersOnRedirection(selectedCardId, ticketingStatusOptionsList,);
      getTicketsData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, '', filters ? JSON.stringify(filters) : [], ticketingSystemFilter?.value, false);
    }
  }, [ticketingStatusOptionsList, ticketingSystemFilter, filterValue])

  const fetchSalesforceReferenceStatus = async () => {
    try {
      setShowTicketStatusLoader(true);
      const response = await dispatch(fetchSalesForceReferenceStatus()).unwrap();
      if (response && response?.length > 0) {
        setTicketingStatusOptionsList(response)
        return response
      }
    } catch (error) {
      console.error(error)
    } finally {
      setShowTicketStatusLoader(false)
    }

  }

  const fetchData = useCallback(async () => {
    const { state } = location || {};
    const status = state?.status;
    let options = await fetchSalesforceReferenceStatus()
    let filters = await handleStatusValueForFiltersOnRedirection(status, options);
    // REMINDER: On change of tenant from header, even after clearing the filters previous filter were retained so passing it as empty for initial fetch
    getTicketsData(null, '', filters ? JSON.stringify(filters) : [], null, true, location?.state?.status === "recently_open");
  }, [location, user?.tenant?.id])

  const getExportAPIRequest = useCallback(() => {
    return {
      filetype: 'csv',
      dataOf: 'tickets',
      ticketingSystem: ticketingSystemFilter?.value?.toLowerCase(),
      totalCount: totalTicketCount,
      tenantId: user?.tenant?.name === "Sangoma" ? null : user?.tenant?.id,
      ...getFilterRequest({ limit: totalTicketCount, offset: 1,  sortField: paginationState?.sortField || 'updated_at', sortOrder: paginationState?.sortOrder || 'desc' })?.params
    }
  }, [ticketingSystemFilter, totalTicketCount, filterValue, searchInputValue, paginationState, user?.tenant?.name, user?.tenant?.id])

  const handleRefreshUrl = useCallback(() => {
    setRefreshLoader(true)
    try {
      getTicketsData(null, null, null, ticketingSystemFilter?.value)
    } catch (error) {
      console.error(error);
      dispatch(resetTickets());
      
    } finally {
      setRefreshLoader(false)
    }
  }, [ticketingSystemFilter, filterValue, searchInputValue])

  const enableFilteredExportBtn = useCallback(()=>{
    const removedExternalFromFilter = filterValue && filterValue?.length > 0 && filterValue?.filter(item => item?.key !== "type")    
    return searchInputValue?.length > 0 || removedExternalFromFilter?.length > 0
  },[searchInputValue, filterValue])

  useEffect(() => {
    setShowTicketCardLoader(true);
    setIsLoading(true);
    setFilterValue([])
    setTicketingSystemFilter([])
    setTenantFilter([])
    setTicketStatusFilter([])
    setSearchInputValue();
    fetchData();
    if (location.state) {
      navigate(location.pathname, { state: null })
    }
    return () => {
      // dispatch(resetTickets());
      toast.dismiss()
      setHighLightFilter(false);
      
    }
  }, [user?.tenant?.id])

  useEffect(() => {
    if (tenants && tenants?.length > 0) {
      let allTenantOption = {
        label: 'All', value: 'all'
      };
      let tenantOptions = tenants?.map(tenant => {
        return {
          label: tenant?.display,
          value: tenant?.id
        }
      })
      tenantOptions = tenantOptions?.filter((item) => item?.value !== "");
      tenantOptions?.unshift(allTenantOption)
      setTenantOptionsList(tenantOptions)
    }
  }, [tenants]);


  return (
    <>{showDescriptionPage ? <TicketDetail back={() =>  setShowDescriptionPage(false)}/> :
    <MuiThemedBox key={user?.tenant?.id} className={"main_inner_wrap tickets__dashboard"}>
      <Breadcrumbs text={"tickets"} />
      <Box className="filter_wrap">
        <SearchInputField key={filterValue} debounceProp={ticketingSystemFilter} handleClick={handleSearch} value={searchInputValue} />

        <Box sx={{ display: "flex" }}>
          <Grid spacing={2} container key={filterValue}>
            {enableTicketingSystemFilter() &&
              <Grid item>
                <SelectDropDown className={"ticketing__system__dropdown"} key={ticketingSystemOptionsList} value={ticketingSystemFilter} options={ticketingSystemOptionsList} placeholder={'Ticketing System'} hideFilterIcon={true} onChange={onChangeOfTicketingSystemFilter} />
              </Grid>
            }
            {/* REMINDER: Commenting this for now as only external tickets will be shown */}
            {/* <Grid item>
              <SelectDropDown className={"ticketing__system__dropdown"} key={ticketingSystemOptionsList} value={ticketingTypeFilter} options={ticketTypeOptions} placeholder={'Ticket Type'} hideFilterIcon={true} onChange={onChangeOfTicketTypeFilter} />
            </Grid> */}
            {filterDetails && filterDetails?.length > 0 && filterDetails?.map((item, idx) => {
              if (item?.isEnabled) {
                return (
                  <Grid item key={idx + item?.id}>
                    <MultiSelectDropDown
                      controlShouldRenderValue={false}
                      onChangeValue={item?.onChangeValue}
                      options={item?.options}
                      placeholder={item?.placeholder}
                      containerWidth={"250px"}
                      value={item?.value}
                      onApplyClick={onApplyFilter}
                      highlightFilter={item?.highlightFilter}
                    />
                  </Grid>)
              }
            })}
          </Grid>

        
            <Box sx={{ paddingLeft: '12px' }}>
              <ButtonLayout
                isLoading={refreshLoader}
                text={`Refresh`}
                buttonStyleType={"outlined"}
                icon={
                  <FontAwesomeIcon
                    icon="fa-solid fa-refresh"
                    className="iconPadding"
                  />
                }
                handleClick={handleRefreshUrl}
              />
            </Box>

          <GlobalExportButton
            enableFilteredDataExportButton={enableFilteredExportBtn()}
            heading={'Tickets'}
            text={"Are you sure you want to download tickets list ?"}
            fileName={"tickets"}
            exportApiRequest={getExportAPIRequest()}
          />
        </Box>
      </Box>
      <TableHeader tableHeaderCardDetails={ticketDetails(ticketStatus, filterValue && filterValue?.length > 0 && filterValue?.find(item => item?.key == 'status')?.value, ticketingStatusOptionsList)} handleCardClick={onHeaderCardClick} isLoading={showTicketCardLoader || showTicketStatusLoader || !ticketStatus} md={2.4} lg={2.4} />
      <Box>
        <TableComponent
          className="rdt_table_wrap table_with_header"
          columns={ticketColumns}
          rows={allTickets}
          isLoading={isLoading || showTicketStatusLoader}
          defaultSortField={'updated_at'}
          pagination={true}
          totalRows={totalTicketCount}
          onPageChange={getTicketsData}
          key={tableRefreshKey}
          // paginationDefaultPage	= {paginationState?.offset}
          // paginationResetDefaultPage = {true}
          defaultSortOrder={"desc"}
          defaultSortAsc = {true}
        />
      </Box>
    </MuiThemedBox>}
    </>
  )
}

