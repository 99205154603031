import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ActionComponent(props) {

  return (
    <div className='action_component_wrap'>
       {!props?.disableEdit && <FontAwesomeIcon className=" action_edit_icon" icon="fa-solid fa-pen" onClick={() => props.onEditClick(props?.row)}/>}
        {!props?.disabledropDown && <FontAwesomeIcon className=" action_dropdown_icon" icon="fa-solid fa-caret-down" onClick={(e) => props.onDropdownClick(e, props?.row)}/>}
    </div>
  )
}
