import React, { useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import FormPopup from "components/common/modal/FormPopup";
import Header from "components/common/modal/Header";
import { unMapData } from "store/devices";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import './binding.css';
import TooltipLabel, { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel';
import ProgressLoading from "components/common/ProgressLoading";
import { MuiThemedButton, MuiTypography, ReactDataTableStyles } from 'components/common/MuiThemedComponents';


export default function MappedEditPopup({data, handleClosePopup, adminTenant, ...props}) {
    const [rows, setRows] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [isMapping, setIsMapping] = useState(false)
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
    useEffect(()=> {
        getRows()
    },[user?.tenant])


    const getRows = () => {
        const rowData = [];
            data?.sdwanOptions?.map(j => {return rowData.push({device: j.label, service: 'SDWAN', id: j.value})})
            data?.wifiOptions?.map(j => {return rowData.push({device: j.label, service: 'WIFI', id: j.value, network_id:j.meraki_network_id})})
            data?.circuits?.map(j => {return rowData.push({netboxId: j.netbox_id, device: j.label, service: 'CIRCUITS', id: j.value})})
            data?.cradlepointOptions?.map(j => {return rowData.push({device: j.label, service: 'CRADLEPOINT', id: j.value})})
            data?.fortinet?.map(j => {return rowData.push({device: j.label, service: 'FORTINET', id: j.value, customIp: j.fotinetCustomIp})})        
        setRows(rowData)
    }

    const getServiceData = (services) => {
        let service = ''
        if (services == 'SDWAN') {
            service = 'velocloud';
        }else if (services == 'WIFI') {
            service = 'meraki';
        }else if (services == 'CIRCUITS') {	
            service = 'wug';
        }else if (services == 'CRADLEPOINT') {
            service = `cradlepoint`;
        }else if (services == 'FORTINET') {
            service = 'fortinet';
        }else{
            service = services.toLocaleLowerCase()
        }
        return service;
    }

    const onDeviceSelect = (e, row) => {
        let devices = [...selectedDevices];
        let deviceIds = devices?.map(i => i.id);
        if(!deviceIds.includes(row?.id) && row?.service !== 'WIFI' && row?.service !== 'SDWAN') {
            devices.push({ id: row?.id,service: getServiceData(row?.service), netboxId: row?.netboxId});
        }else if(!deviceIds.includes(row?.id) && row?.service == 'SDWAN') {
            devices.push({ id: row?.id,service: getServiceData(row?.service), tenantId:  user?.tenant?.id? user?.tenant?.id : adminTenant});
        }else if(!deviceIds.includes(row?.id) && row?.service == 'WIFI'){
            devices.push({ id: row?.id,service: getServiceData(row?.service), network_id: row?.network_id});
        }else {
            devices = devices?.filter(i => i?.id !== row.id);
        }
        setSelectedDevices(devices)
    }


    const handleUnmap = async() => {
     
        if(props?.handleUnmap)
            {
                setIsMapping(true)
                await props?.handleUnmap(data?.deviceId, selectedDevices);
                setIsMapping(false);
                return;
            }  
        let req = {
            siteId: data?.id,
            data : selectedDevices
        }
        if(selectedDevices?.length > 0) {
        setIsMapping(true)
        let res = await dispatch(unMapData(req));
        if (res?.payload) {
            toast.success("Site Unmaped successfully");
            handleClosePopup('callApi')
        }
        else {
            toast.error("Something went wrong");
        }
        setIsMapping(false)
    }
    else {
        toast.error("Please Select Device");
    }
 }

  return (
      <FormPopup open={true} size={"modal_size"} className={ "link_wrapper"}>
          <Header text={"Manage Mapping"} close={handleClosePopup} />
          <Box className="site_service_popup_name formWrapper">
              <Box className="flex-start link_heading">
                  <MuiTypography className="head">Site Display Name : &nbsp;</MuiTypography>
                  <StyledTooltip placement="right" title={`Site Name: ${data?.site_name}`} >
                      <span className='label__loader'>
                          <div style={{ fontWeight: 500, color: user?.themePreference === 'dark' ? '#648cd2' : '' }}>
                              {data?.site_display_name ? data?.site_display_name : data?.site_name}
                          </div>
                      </span>
                  </StyledTooltip>
              </Box>
          <Box style = {{padding: '20px 0px'}}>
             <DetailedComponent data = {rows} onDeviceSelect={onDeviceSelect} selectedDevices = {selectedDevices}/>
           </Box>
            <div className='unmapped__button'>
                <MuiThemedButton showLoader={isMapping} onClick={handleUnmap} variant={"outlined"} type="submit"
                      className={user?.themePreference === 'light' ? "button-outlined flex" : ""}>
                      UNMAP
                </MuiThemedButton>
            </div>
          </Box>
      </FormPopup>
  )
}

const DetailedComponent = ({data, onDeviceSelect, ...props}) => {
    const user = useSelector((state) => state.user);
    let selectedDevice =  Array.isArray(props?.selectedDevices) && props?.selectedDevices?.map(i => i.id) || []
    const headers = [
        { name: 'Devices', width: '85%', cell: (row) => (
       <div style={{width: '50%', cursor: 'pointer', display: 'flex'}} onClick={e => onDeviceSelect(e, row)}>
                               <input checked = {!row?.customIp ? selectedDevice?.includes(row?.id):false} type = 'checkbox' style={{marginRight: '10px'}} disabled={!!row?.customIp} />
                             <TooltipLabel text ={row?.device}/>
                            </div>)},
        { name: 'Service', minWidth: '150px' , maxWidth: '150px',  cell: (row) => (<TooltipLabel text = {(row?.service)} />)},
    ]

    createTheme('table__dark__theme',
    ReactDataTableStyles
  );
    return (
        <DataTable
            className={`binding__popup rdt_table_wrap hover_table ${user?.themePreference === 'dark' ? 'rdt__table__darkMode' : ''} `}
            columns={headers}
            data={data}
            progressPending={data.length <= 0 ? true : false}
            progressComponent={<ProgressLoading />}
            fixedHeader
            theme={user?.themePreference === 'dark' ? "table__dark__theme" : null}
        />

    )
}
