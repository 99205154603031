import Box from "@mui/material/Box";
import MiniDrawer from "./MiniSidebar";
import { useState } from "react";
import styled from "@emotion/styled";
import RouteChangeTracker from "routes/RouteChangeTracker";
import { MuiThemedBox } from "components/common/MuiThemedComponents";
import { useSelector } from "react-redux";

const Wrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ open }) => ({
	flexGrow: 1,
	backgroundColor: '#f2f2f3',
	marginTop: '53px',
	minHeight: '100%',
	height: 'calc(100vh - 53px)',
	...(open && {
		width: 'calc(100% - 190px)',
		marginLeft: '190px',
	}),
	...(!open && {
		width: 'calc(100% - 52px)',
		marginLeft: '52px',

	}),
}));

export default function MainLayout({ children }) {
	const [open, setOpen] = useState(false);
	const user = useSelector((state) => state.user);
	return (
		<>
		<RouteChangeTracker/>
			<MiniDrawer setOpen={setOpen} open={open} />
			<Wrapper sx={{
				backgroundColor: (theme) => theme.palette.mode === "dark"
					? "#222831 !important"
					: "",
			}}  open={open} className="wrapper">
				<MuiThemedBox className={user?.themePreference === 'dark' ? "main_wrapper main__dashboard__darkMode" : "main_wrapper"}>{children}</MuiThemedBox>
			</Wrapper>
			<footer className={user?.themePreference === 'dark' ? "nfStatus_footer nfStatus_footer__darkMode" : "nfStatus_footer"} ><p>{ process.env.REACT_APP_CURRENT_VERSION} | &#169; 2024 Sangoma Technologies Inc. | <a target="_blank"  rel="noreferrer" href="https://www.sangoma.com/privacy-policy/">Privacy Policy</a></p></footer>
		</>
	);
}
