import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  fetchEventLogDataByTenant } from 'store/eventLogs'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import DataTable from 'react-data-table-component';
import ProgressLoading from 'components/common/ProgressLoading';
import { DateTime, Interval } from "luxon";
import Timer from "components/component/link/Timer";
import './flappingLogs.css'

export default function EventLogs({row, tenantId, dateFilter}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [eventData, setEventData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getEventData();
    }, [user?.tenant])


    const getEventData = async () => {
        setIsLoading(true);
        let data = await dispatch(fetchEventLogDataByTenant({ tenantId: tenantId, siteId: row?.site_id, deviceId: row?.netbox_id, dateFilter:dateFilter }))
        let formatData = data?.payload?.map((item, index)=> {
            let last_status_time = data?.payload[index - 1]
            return (
                {
                    ...item,
                    since: last_status_time && index !== 0 ? DateTime.fromMillis(parseInt(last_status_time?.created_at)) : null,
                })

        })
        setEventData(formatData); 
        setIsLoading(false);
    }

    const getDateTime = (dateTime) => {
        return DateTime.fromMillis(parseInt(dateTime)).toFormat("yyyy-MM-dd h:mm a")
    };
    
    const columns = [
        {
            name: " ",
            dataType: "String",
            weight: 1,
            maxWidth: '5% !important',
            minWidth: '5% !important',
            isEnabled: true,
        },
        {
            name: "Status",
            selector: (row) => <span className="flex">
                <span className={row.status?.toLowerCase() === "up" ? "status status_active"
                    : row.status?.toLowerCase() === "down" ? "status status_down"
                        : row.status === null ? "status status_null" : "status status_inactive"}>
                    {row?.status}
                </span>
            </span>,
            dataType: "String",
            weight: 1,
            isEnabled: true,
        },
        {
            selector: (row) => <TooltipLabel text={getDateTime(row.created_at)} />,
            name: "Date & Time (UTC)",
            dataType: "String",
            weight: 1,
            isEnabled: true,
        },
        {
            selector: (row) => {
                let date = row?.created_at && row?.since !== null ? Interval.fromDateTimes(DateTime.fromMillis(parseInt(row?.created_at)), row?.since).toDuration(['years', 'months', 'weeks', 'days',
                    'hours', 'minutes', 'seconds']).toObject()
                    :  '--';
                let format = date === '--' ? <Timer targetDate={row.created_at} /> : DurationFormat(date);
                return (
                    <span>
                        {format}
                    </span>
                )
            },
    
    
            name: "duration",
            id: "up/down_duration",
            sortable: true,
            wrap: true,
            width: 130,
        },
    ]

    const DurationFormat = d => {
        let year = ''; let month = ''; let day = ''; let hour = ''; let min = ''; let week = '';
        if (d.years > 0) year = `${d.years + (d.years > 1 ? ' years ' : ' year ')}`
        if (d.months > 0) month = `${d.months + (d.months > 1 ? ' months ' : ' month ')}`
        if (d.weeks > 0) week = `${d.weeks + (d.weeks > 1 ? ' weeks ' : ' week ')}`
        if (d.hours > 0) hour = `${d.hours + (d.hours > 1 ? ' hours ' : ' hour ')}`
        if (d.minutes > 0) min = `${d.minutes + (d.minutes > 1 ? ' minutes ' : ' minute ')}`
        if (d.days > 0) day = `${d.days + (d.days > 1 ? ' days ' : ' day ')}`
        if((year === '' && month === '' && day === '' && hour === '' && min === '' && d.seconds > 1)) return Math.floor(d.seconds) + ' seconds';
        if ((year === '' && month === '' && day === '' && hour === '' && min === '')) return 0 + ' minute'
    
        if (d.days === 0  && d.years === 0 && d.months === 0 && d.weeks === 0)   {
                
            return (hour + min)
    
        }
    
        return (year + month + week + day)
    }

    
    return (
        <div className='eventLog'>
        <DataTable
            columns={columns}
            data={eventData}
            className={user?.themePreference === 'dark' ?  "rdt__table__darkMode": ''}
            theme={user?.themePreference === 'dark' ? "table__dark__theme" : ''}
            progressPending={isLoading}
            onPageChange={getEventData}
            progressComponent={<ProgressLoading />}
            pagination = {false}
        /></div>
)
}




