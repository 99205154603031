// ** React Imports
import PKCEAuth from "@star2star/cpaas-pkce-auth";
import axios from "axios";
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { setUser, setTenant } from 'store/user';
import { setTenantsList } from 'store/devices';

// ** Defaults
const defaultProvider = {
    logout: () => Promise.resolve(),
    isAuthorized: false,
    isLoading: false
}

const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {

    // ** Hooks
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch();

    const initAuth = async () => {
        try {
            if (!PKCEAuth.isAuthorized()) {
                await PKCEAuth.authorize();
            }
            if (PKCEAuth.isAuthorized()) {
                if(user?.status !== "logged-in"){
                    await logActivity('logged-in');
                }
               
                const token = await PKCEAuth.getAccessToken();
                const tokenId = await PKCEAuth.getIdToken();
                const userInfo = await getUserInfo(token, tokenId);
                const userPermissions = await getUserPermissions(token, tokenId);
                if (userInfo && userPermissions) {
                    await dispatch(setUser({
                        id: userInfo?.id,
                        email: userInfo.email,
                        name: userInfo.name,
                        userRole: userInfo.userRole,
                        roleId: userInfo.roleId,
                        canCreateSubUsers: userInfo.canCreateSubUsers,
                        role: userPermissions.role,
                        acl: userPermissions.acl,
                        status: 'logged-in',
                        themePreference:userInfo?.theme ? userInfo?.theme : 'light'
                    }));
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status && error.response.status === 401) {
                toast.error("Unauthorized: Redirecting to login page...")
            } else {
                toast.error("Something went wrong while authenticating");
            }
        }
        await setIsLoading(false)
    }

    useEffect(() => {
        initAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserInfo = async (token, tokenId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/info`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "x-token-id": `${tokenId}`,
                    }
                }
            );
            return response.data
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const getUserPermissions = async (token, tokenId) => {
        try {
            const deviceId = localStorage.getItem(`${process.env.REACT_APP_OAUTH_CLIENT_ID}_device_id`);
            if (!deviceId) {
                return;
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/role-permissions/acl`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "x-token-id": `${tokenId}`,
                        "x-device-id": `${deviceId}`,
                    }
                }
            );
            return response.data
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const logActivity = async (status) => {
        try {
            const deviceId = localStorage.getItem(`${process.env.REACT_APP_OAUTH_CLIENT_ID}_device_id`);
            if (!deviceId) {
                return;
            }
            const token = await PKCEAuth.getAccessToken();
            const tokenId = await PKCEAuth.getIdToken();
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/users/log/activity`,
                { deviceId, status },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "x-token-id": `${tokenId}`,
                        "x-device-id": `${deviceId}`,
                    }
                }
            );
            return response.data
        } catch (error) {
            console.log(error);
            return false
        }
    }

    const handleLogout = async () => {
        setIsLoading(true);
        await logActivity('logged-out');
        await dispatch(setTenant(undefined));
        await dispatch(setTenantsList(undefined));
        dispatch(setUser({
            id: user.id,
            email: user.email,
            name: user.name,
            userRole: user.userRole,
            canCreateSubUsers: user.canCreateSubUsers,
            role: user.role,
            acl: user.acl,
            roleId: user.roleId,
            themePreference: user.themePreference,
            status: 'logged-out',

        }));
        PKCEAuth.logout();
        setTimeout(() => {
            setIsLoading(false);
        }, 1300)
    }

    const values = {
        user,
        logout: handleLogout,
        isAuthorized: PKCEAuth.isAuthorized(),
        isLoading: isLoading
    }

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider };

