import React from 'react'
import { StyledTooltip } from '../TooltipLabel/TooltipLabel'

const ColumnHeaderWithTooltip = ({ tooltipText, columnName }) => {
    return (
        <StyledTooltip placement="top" title={tooltipText} >
            {columnName}
        </StyledTooltip>
    )
}

export default ColumnHeaderWithTooltip
