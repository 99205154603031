import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

// ** Fetch changelogs data
export const fetchChangeLogs = createAsyncThunk(
    "appReports/fetchChangeLogs",
    async (data) => {
        const { tenantId, tenantName, ...rest  } = data;
        let url = '';
        if (tenantName !== "Sangoma" && tenantId) {
            url = `${base_url}/journals/tenant/${tenantId}`;
        } else {
            url = `${base_url}/journals`;
        }
        const response = await axios.get(url, {params: rest});
        return response.data;
    }
);

export const fetchChangeLogsDetailsById = createAsyncThunk(
    "appReports/fetchChangeLogsDetailsById",
    async (id) => {
        const response = await axios.get(`${base_url}/changelogs/${id}`);
        return response.data;
    }
);

// ** Fetch changelogs data
export const fetchUsermanagementChangeLogs = createAsyncThunk(
    "appReports/fetchUsermanagementChangeLogs",
    async (req) => {
        let url = `${base_url}/users/changelogs`;
        const response = await axios.get(url, {params: {...req}});
        return response.data;
    }
);


export const ReportsSlice = createSlice({
    name: "changelogs",
    initialState: {
        drillDownDeviceData: undefined,
        scheduled: undefined,
        tenants: undefined,
        id: undefined,
        type: "",
        primaryText: ""
    },
    reducers: {
        setChangelogPath: (state, action) => {  
            state.id = action.payload.id; 
            state.type = action.payload.type;
            state.primaryText = action?.payload?.primaryText
         },
    }
});
export const { setChangelogPath} = ReportsSlice.actions;
export default ReportsSlice.reducer;
