import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "services/Axios";
import { setLoading } from "store/user";

let base_url = process.env.REACT_APP_BASE_URL;

export const fetchSyncData = createAsyncThunk(
	"nfstatus/fetchSyncData",
	async (data) => {
		const { tenantId, tenantName, ...rest } = data;
		let url = "";

		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/sync/getsyncprocessdata/${tenantId}`;
		} else {
			url = `${base_url}/sync/getsyncprocessdata`;
		}
		const response = await axios.get(url, {params: {...rest}});
		return response.data;
	}
);

export const fetchSyncDataById = createAsyncThunk(
	"nfstatus/fetchSyncDataById",
	async (req, { dispatch }) => {
		dispatch(setLoading(true));
		const response = await axios.get(`${base_url}/sync/syncrecords/${req?.reportId}`, {params: {tenantId: req?.tenantId}});
		dispatch(setLoading(false));
		return response.data;
		
	}
);

export const updateSyncInventory = createAsyncThunk(
	"nfstatus/updateSyncInventory",
	async (data) => {
		const response = await axios.post(`${base_url}/sync/updateConflictData`, { ids: data });
		return response.data;
	}
);

export const startSyncProcess = createAsyncThunk(
	"nfstatus/startSyncProcess",
	async (data) => {
		if (data) {
			const response = await axios.post(`${base_url}/sync/tenant`, data);
			return response.data;
		} else {
			const response = await axios.post(`${base_url}/sync/tenant`);
			return response.data;
		}
	}
);

export const ReportsSlice = createSlice({
	name: "sync",
	initialState: {
		reportData: [],
	},
	reducers: {
		setReport: (state, action) => {
			state.reportData = action.payload
		},
	},
	extraReducers: {
		[fetchSyncDataById.pending]: (state) => {
			state.status = "loading";
		},
		[fetchSyncDataById.fulfilled]: (state, { payload }) => {
			state.reportData = payload;
			state.status = "success";
		},
		[fetchSyncDataById.rejected]: (state) => {
			state.status = "failed";
		},
	},
});

export const { setReport } = ReportsSlice.actions;
export default ReportsSlice.reducer;
