import React, {useState} from 'react'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import DataTable from 'react-data-table-component';
import EventLogs from './EventLogs';
import {useDispatch} from 'react-redux';
import {updateRecord} from 'store/eventLogs';

export default function FlappingDevice({data, user, tenantId, dateFilter, updateLogState}) {
    const [expandedRow, setExpandedRow] = useState(null);
    const dispatch = useDispatch();


    const deviceColumns = [
        {
            cell: (row) => {
              return(
                  <div className='flex'>
                  <TooltipLabel text={row?.name + `${row?.networkName ? '(' + row?.networkName + ')' : ''}  (${getServiceName(row?.dservice)}) `+ '('+ row?.count +' logs)'} />
                  {row?.is_new_log && <div className='flapped_dot'>New Logs</div>}
                 </div>)
              }
          
  ,
            name: "Device Name",
            dataType: "String",
            weight: 1,
            minWidth: '85% !important'
        }
    ]


    const getServiceName = (service) => {
        let res = service === "velocloud"
        ? "SDWAN"
        : service === "meraki"
            ? "WiFi"
            : service === "wug"
                ? "Circuits"
                : service === "cradlepoint"
                    ? "Cradlepoint"
                    : service === "fortinet"
                        ? "Fortinet" : "--";
                        return res;
    }


    const onRowToogle = async(toogleState, row) => {
       if(toogleState){ 
        const cloneDeviceData = [...data.devices];
        let deviceToUpdate = cloneDeviceData.find(i => i.netbox_id === row?.netbox_id);
        deviceToUpdate.is_new_log = false;
        updateLogState(cloneDeviceData, row?.site_id);
        setExpandedRow(row);
        const deviceId = JSON.stringify([row?.netbox_id]);
        const req = {userId : user?.id, deviceIds : deviceId, tenantId : user?.tenant?.id ? user?.tenant?.id: tenantId};
        await dispatch(updateRecord(req));  
    }
       else setExpandedRow(null)
    }

    return (
      <div className="link__monitoring subComponent flapping_device_table">
      <DataTable
      key={data?.devices}
      className={`${user?.themePreference === 'dark' ?  "rdt__table__darkMode": ''}`}
      theme={user?.themePreference === 'dark' ? "table__dark__theme" : null}
      columns={deviceColumns}
      data={data?.devices}
      expandableRows = {true}    
      expandableRowsComponent={EventLogs}
      onRowExpandToggled = {onRowToogle}
      expandableRowsComponentProps={{row:expandedRow, tenantId: tenantId, dateFilter: dateFilter}}
      />
     </div>
    )
  }
  
 
