/* eslint-disable no-debugger */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "services/Axios";
import { setTenant } from "store/user";

let base_url = process.env.REACT_APP_BASE_URL;

export const fetchScheduledDevices = createAsyncThunk(
	"appReports/fetchScheduledDevices",
	async () => {
		const response = await axios.get(`${base_url}/`);
		return response.data;
	}
);


export const fetchLinkMonitorStatusData = createAsyncThunk(
	"nfstatus/fetchLinkMonitorStatusData",
	async (data) => {
		const { tenantId, tenantName, params } = data;
		let url = `${base_url}/monitor-devices/linkStatus`;
		let req={...params}
		if (tenantName !== "Sangoma" && tenantId) {
			req= {
				tenantId,
				...req
			}
		}
		const response = await axios.get(url, {
			params: req });
		return response.data;
	}
);



// ** Fetch devices data
export const fetchDevicesData = createAsyncThunk(
	"appReports/fetchDevicesData",
	async (data) => {
		const { tenantId, tenantName, ...rest } = data;
		let url = '';
		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/devices/byTenantId/${tenantId}`;
		} else {
			url = `${base_url}/devices`;
		}
		const response = await axios.get(url,{params: {...rest} });
		return response.data;
	}
);

export const fetchDevicesDataForFlapping = createAsyncThunk(
	"nfstatus/fetchDevicesDataForFlapping",
	async (data) => {
		const {id, service} = data
		let url = '';
			url = `${base_url}/devices/deviceDataForFlapping/${id}?service=${service}`;
		const response = await axios.get(url, service);
		return response.data;
	}
);

export const fetchDeviceDetailData = createAsyncThunk(
	"nfstatus/fetchDeviceDetailData",
	async (id) => {
		const response = await axios.get(`${base_url}/devices/devicedetails/${id}`);
		return response.data;
	}
);

export const deleteDevice = createAsyncThunk(
	"nfstatus/deleteDevice",
	async (data) => {
		const {id, role} = data
		const response = await axios.delete(`${base_url}/devices/deletedevice/${id}`, {params:{role: role}});
		return response.data;
	}
);


export const fetchLinkMoniterDataForStatus = createAsyncThunk(
	"nfstatus/fetchLinkMoniterDataForStatus",
	async (data) => {
		let url = '';
			url = `${base_url}/monitor-devices/byDeviceId`;
		const response = await axios.post(url, data);
		return response.data;
	}
);

export const fetchUserTenantsData = createAsyncThunk(
	"nfstatus/fetchUserTenantsData",
	async (user, {dispatch}) => {
		const response = await axios.get(`${base_url}/users/tenants`);
		let list = response?.data;
		setTenantsList(list)
		
		const tenantExists = list.filter(tnt => tnt.id === user?.tenant?.id);
		if ((list && list.length > 0 && tenantExists && tenantExists.length < 1) || (user.tenant === '' && list && list.length > 0)) {
			dispatch(setTenant(list[0]));
		}
		return response.data;
	}
);

export const fetchTenantsData = createAsyncThunk(
	"nfstatus/fetchTenantsData",
	async (req) => {
		const response = await axios.get(`${base_url}/tenants`,{
			params: req });
		return response.data;
	}
);

export const fetchTenantsDataById = createAsyncThunk(
	"nfstatus/fetchTenantsDataById",
	async (id) => {
		const response = await axios.get(`${base_url}/tenants/${id}`);
		return response.data;
	}
);

export const fetchServiceTenants = createAsyncThunk(
	"nfstatus/fetchServiceTenants",
	async (service) => {
		const response = await axios.get(`${base_url}/mapping-sites/tenants/${service}`);
		return response.data;
	}
);

export const createTenantsData = createAsyncThunk(
	"nfstatus/createTenantsData",
	async (params) => {
		const response = await axios.post(`${base_url}/tenants/addtenant`, params);
		return response.data;
	}
);


export const updateTenantsData = createAsyncThunk(
	"nfstatus/updateTenantsData",
	async (data) => {
		const response = await axios.patch(`${base_url}/tenants/updatetenant/${data.id}`, data.data);
		return response.data;
	}
);

export const deleteTenantData = createAsyncThunk(
	"nfstatus/deleteTenantData",
	async (data) => {
		const response = await axios.delete(`${base_url}/tenants/deletetenant/${data.id}`);
		return response.data;
	}
);

export const fetchContactDataByTenantId = createAsyncThunk(
	"nfstatus/fetchContactDataByTenantId",
	async (data) => {
		const {id, req} = data;
		const response = await axios.get(`${base_url}/tenants/contact/${id}`, {params:req});
		return response.data;
	}
);

export const createContactData = createAsyncThunk(
	"nfstatus/createContactData",
	async (data) => {
		const response = await axios.post(`${base_url}/tenants/addcontact`, data);
		return response.data;
	}
);

export const updateContactData = createAsyncThunk(
	"nfstatus/updateContactData",
	async (data) => {
		const { id, req } = data
		const response = await axios.patch(`${base_url}/tenants/updatecontact/${id}`, req);
		return response.data;
	}
);

export const deleteContactData = createAsyncThunk(
	"nfstatus/deleteContactData",
	async (data) => {
		const response = await axios.delete(`${base_url}/tenants/deletecontact/${data?.id}`)
		return response.data
	}
);


export const fetchSitesData = createAsyncThunk(
	"nfstatus/fetchSitesData",
	async (data) => {
		const { tenantId, tenantName, ...rest} = data;
		let url = '';
		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/sites/byTenantId/${tenantId}`;
		} else {
			url = `${base_url}/sites`;
		}
		const response = await axios.get(url,{
			params: {...rest} });
		return response.data;
	}
);

export const fetchSitesDataById = createAsyncThunk(
	"nfstatus/fetchSitesDataById",
	async (data) => {
		let url = '';
			url = `${base_url}/sites/${data}`;
		const response = await axios.get(url);
		return response.data;
	}
);
export const createSiteData = createAsyncThunk(
	"nfstatus/createSiteData",
	async (params) => {
		const response = await axios.post(`${base_url}/sites/addsite`, params);
		return response.data;
	}
);

export const updateSiteGroupData = createAsyncThunk(
	"nfstatus/updateSiteGroupData",
	async (params) => {
		const response = await axios.patch(`${base_url}/sites/updatesite-groups`, params);
		return response.data;
	}
);

export const siteUploadDataProgress= createAsyncThunk(
	"nfstatus/siteUploadDataProgress",
	async ({id, type}) => {
		const response = await axios.get(`${base_url}/sites/progress-sites/${id}`, {params:{type}});
		return response.data;
	}
);

export const updateSiteData = createAsyncThunk(
	"nfstatus/updateSiteData",
	async (data) => {
		const { id, params } = data
		const response = await axios.patch(`${base_url}/sites/updatesite/${id}`, params);
		return response.data;
	}
);

export const deleteSiteData = createAsyncThunk(
	"nfstatus/deleteSiteData",
	async (data) => {
		const response = await axios.delete(`${base_url}/sites/deletesite/${data?.id}`)
		return response.data
	}
)

// Notes==

export const createNotesData = createAsyncThunk(
	"nfstatus/createNotesData",
	async (data) => {
		const response = await axios.post(`${base_url}/notes/addnote`, data);
		return response.data;
	}
);

export const updateNotesData = createAsyncThunk(
	"nfstatus/updateNotesData",
	async (data) => {
		const {feature_id, notes, feature} = data
		const response = await axios.put(`${base_url}/notes/updatenote/${feature_id}`, {notes, feature});
		return response.data;
	}
);

export const deleteNotesData = createAsyncThunk(
	"nfstatus/deleteNotesData",
	async (data) => {
		const response = await axios.put(`${base_url}/notes/deletenote/${data?.featureId}`, {feature: data?.feature})
		return response.data
	}
)

export const fetchLinkMonitorData = createAsyncThunk(
	"nfstatus/fetchLinkMonitorData",
	async (data) => {
		const { tenantId, tenantName, params } = data;
		let url = ``;
		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/monitor-devices/byTenantId/${tenantId}`;
		} else {
			url = `${base_url}/monitor-devices`;
		}
		const response = await axios.get(url, {
			params: params });
		return response.data;
	}
);

export const fetchSiteLinkData = createAsyncThunk("nfstatus/fetchSiteLinkData", async(data) => {
	const { tenantId, siteId } = data;
	const url = `${base_url}/monitor-devices/bySiteId/${tenantId}/${siteId}`
	const response = await axios.post(url);
	return response.data;
})

export const fetchDashboardData = createAsyncThunk(
	"nfstatus/fetchDashboardData",
	async (data) => {
		const { service, tenantId, tenantName } = data;
		let url = ``;
		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/devices/dashboard/byTenantId/${tenantId}?service=${service}`;
		} else {
			url = `${base_url}/devices/dashboard?service=${service}`;
		}
		const response = await axios.get(url);
		return response.data;
	}
);

export const fetchMappedSite = createAsyncThunk(
	"nfstatus/fetchMappedSite",
	async (data) => {
		const { tenantId, serviceId, service } = data;
		const response = await axios.post(`${base_url}/sites/fetchMappedSitesList`, {
			serviceId: serviceId,
			service: service,
			tenantId: tenantId,
		});
		return response.data;
	}
);

export const fetchSiteBindingByTenant = createAsyncThunk(
	"nfstatus/fetchSiteBindingByTenant",
	async (data) => {
		const { tenantId } = data;
		if (tenantId) {
			const response = await axios.get(`${base_url}/sites/byTenantId/${tenantId}`);
			return response.data;
		}
	}
);

export const fetchVeloUnMappedSite = createAsyncThunk(
	"nfstatus/fetchVeloUnMappedSite",
	async (data) => {
		const { tenantId, eid, type } = data;
		const response = await axios.post(`${base_url}/services/get_unmapped_enterprise_edges`, {
			tid: tenantId,
			eid: eid,
			type: type
		});
		return response.data;
	}
);

export const fetchMerakiUnMappedNetwork = createAsyncThunk(
	"nfstatus/fetchMerakiUnMappedNetwork",
	async (data) => {
		const response = await axios.post(`${base_url}/services/get-unmapped-meraki-networks`, data);
		return response.data;
	}
);

export const fetchWugUnMappedDevice = createAsyncThunk(
	"nfstatus/fetchWugUnMappedDevice",
	async (data) => {
		const response = await axios.post(`${base_url}/services/get-unmapped-wug-devices`, data);
		return response.data;
	}
);

export const fetchCradlePointUnMappedDevice = createAsyncThunk(
	"nfstatus/fetchCradlePointUnMappedDevice",
	async (data) => {
		const response = await axios.post(`${base_url}/services/get-unmapped-cradlepoint-devices`, data);
		return response.data;
	}
);

export const fetchFortinetUnMappedDevice = createAsyncThunk(
	"nfstatus/fetchFortinetUnMappedDevice",
	async (data) => {
		const response = await axios.post(`${base_url}/services/get-unmapped-fortinet-devices`, data);
		return response.data;
	}
);

export const fetchVeloSiteBindingByTenant = createAsyncThunk(
	"nfstatus/fetchVeloSiteBindingByTenant",
	async (data) => {
		const response = await axios.post(`${base_url}/services/get_unmapped_enterprise_edges`, data);
		return response.data;
	}
);

export const createSiteBinding = createAsyncThunk(
	"nfstatus/createSiteBinding",
	async (params) => {
		const response = await axios.post(
			`${base_url}/mapping-sites/store-mapping-data`,
			params
		);
		return response.data;
	}
);

export const fetchServiceList = createAsyncThunk(
	"appReports/fetchServiceList",
	async () => {
		const response = await axios.get(`${base_url}/services/getallservices`);
		return response.data;
	}
);

export const createService = createAsyncThunk(
	"appReports/createService",
	async (data) => {
		const response = await axios.post(`${base_url}/services/addservice`, data);
		return response.data;
	}
)

export const updateService = createAsyncThunk(
	"appReports/updateService",
	async (data) => {
		const { id, obj } = data
		const response = await axios.patch(`${base_url}/services/updateservice/${id}`, obj);
		return response.data;
	}
)

export const createServer = createAsyncThunk(
	"appReports/createServer",
	async (data) => {
		const response = await axios.post(`${base_url}/services/addserver`, data);
		return response.data;
	}
)

export const unMapData = createAsyncThunk(
	"appReports/unMapData",
	async (req) => {
		const response = await axios.post(`${base_url}/mapping-sites/unmapsite`, {...req});
		return response.data;
	}
);

export const fetchLinkHistoryData = createAsyncThunk(
	"nfstatus/fetchLinkHistoryData",
	async (data) => {
		const {id, req} = data;
		if (id) {
			const response = await axios.get(`${base_url}/monitor-devices/flapping/${id}`, {params:req});
			return response.data;
		}
		return []
	}
);

export const fetchMoniterDataByDeviceId = createAsyncThunk(
	"nfstatus/fetchMoniterDataByDeviceId",
	async (id) => {
		if (id) {
			const response = await axios.get(`${base_url}/mapping-sites/monitor-data-bydeviceid/${id}`);
			return response.data;
		}
		return []
	}
);

export const fetchSiteServiceCount = createAsyncThunk(
	"nfstatus/fetchSiteServiceCount",
	async (data) => {
		const {id, tenantId} = data;
		if (id) {
			const response = await axios.get(`${base_url}/devices/serviceByDeviceCount/${id}`, {params:{tenantId:tenantId}});
			return response.data;
		}
		return []
	}
);

export const fetchChangelogTenant = createAsyncThunk(
	"nfstatus/fetchChangelogTenant",
	async (id) => {
		if (id) {
			const response = await axios.get(`${base_url}/journals/byTenantId/${id}`);
			return response.data;
		}
		return []
	}
);

export const fetchChangelogSite = createAsyncThunk(
	"nfstatus/fetchChangelogSite",
	async (id) => {
		if (id) {
			const response = await axios.get(`${base_url}/journals/bySiteId/${id}`);
			return response.data;
		}
		return []
	}
);

export const fetchChangelogDevice = createAsyncThunk(
	"nfstatus/fetchChangelogDevice",
	async (id) => {
		if (id) {
			const response = await axios.get(`${base_url}/journals/byDeviceId/${id}`);
			return response.data;
		}
		return []
	}
);

export const fetchDeviceRoles = createAsyncThunk(
	"nfstatus/fetchDeviceRoles",
	async () => {
			const response = await axios.get(`${base_url}/devices/deviceroles`);
			return response.data;
	}
);

export const fetchDeviceType = createAsyncThunk(
	"nfstatus/fetchDeviceType",
	async () => {
			const response = await axios.get(`${base_url}/devices/devicetypes`);
			return response.data;
	}
);

export const fetchCircuitsData = createAsyncThunk(
	"nfstatus/fetchCircuitsData",
	async (req) => {
			const response = await axios.get(`${base_url}/devices/circuitData`, {params: {...req}});
			return response.data;
	}
); 

export const unLinkCircuits = createAsyncThunk(
	"appReports/unMapData",
	async (req) => {
		const response = await axios.delete(`${base_url}/circuits/unlink-circuits`, {params: {...req}});
		return response.data;
	}
); 

export const getCircuitList = createAsyncThunk(
	"nfstatus/getCircuitList",
	async (req) => {
			const response = await axios.get(`${base_url}/devices/getCircuitList`, {params: {...req}});
			return response.data;
	}
); 

export const getMonitorAssociateCircuits = createAsyncThunk(
	"nfstatus/getMonitorAssociateCircuits",
	async (req) => {
			const response = await axios.get(`${base_url}/monitor-devices/associatecircuits/${req.deviceId}`);
			return response.data;
	}
); 

export const fetchSiteNames = createAsyncThunk(
	"nfstatus/fetchSiteNames",
	async (data) => {
		const {tenantId } = data;
		if (tenantId) {
			const response = await axios.get(`${base_url}/sites/allSites/byTenantId/${tenantId}`);
			return response.data;
		} 
		
	}
);

export const fetchDeviceManufacturers = createAsyncThunk(
	"nfstatus/fetchDeviceManufacturers",
	async () => {
			const response = await axios.get(`${base_url}/devices/allmanufacturers`);
			return response.data;
	}
);

export const fetchDevicePortInformation = createAsyncThunk(
	"nfstatus/fetchDevicePortInformation",
	async () => {
			const response = await axios.get(`${base_url}/devices/devicePortInformation`);
			return response.data;
	}
);

export const addDeviceAssociateCircuits = createAsyncThunk(
	"nfstatus/addDeviceAssociateCircuits",
	async (data) => {
			const response = await axios.post(`${base_url}/devices/add-device-associatecircuits`, data);
			return response.data;
	}
);

export const updateDeviceAssociateCircuits = createAsyncThunk(
	"nfstatus/updateDeviceAssociateCircuits",
	async (data) => {
			const response = await axios.patch(`${base_url}/devices/update-device-associatecircuits`, data);
			return response.data;
	}
);


export const addDeviceData = createAsyncThunk(
	"nfstatus/addDeviceData",
	async (data) => {
		let url = '';
			url = `${base_url}/devices/add-device`;
		const response = await axios.post(url, data);
		return response.data;
	}
);

export const createDeviceType = createAsyncThunk(
	"nfstatus/createDeviceType",
	async (data) => {
		let url = '';
			url = `${base_url}/devices/adddevicetype`;
		const response = await axios.post(url, data);
		return response.data;
	}
);

export const updateDevieData = createAsyncThunk(
	"nfstatus/updateDevieData",
	async (data) => {
		const { id, req } = data
		const response = await axios.patch(`${base_url}/devices/updatenewdevice/${id}`, req);
		return response.data;
	}
);

export const fetchWebMonitorData = createAsyncThunk(
	"nfstatus/fetchWebMonitorData",
	async (data) => {
		const { tenantId, tenantName, ...req } = data;
		let url = "";
		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/web-monitor/byTenantId/${tenantId}`;
		} else {
			url = `${base_url}/web-monitor`;
		}
        const response = await axios.get(url, {params: {...req}});
        return response.data;
	}
);

export const createWebMonitorData = createAsyncThunk(
	"nfstatus/createWebMonitorData",
	async (data) => {
			const response = await axios.post(`${base_url}/web-monitor/addwebmonitor`, data);
			return response.data;
	}
);

export const updateWebMonitorData = createAsyncThunk(
	"nfstatus/updateWebMonitorData",
	async (data) => {
		const {id, req} = data
			const response = await axios.patch(`${base_url}/web-monitor/updatewebmonitor/${id}`, req);
			return response.data;
	}
);

export const delteWebMonitorData = createAsyncThunk(
	"nfstatus/updateWebMonitorData",
	async ({id}) => {
			const response = await axios.delete(`${base_url}/web-monitor/${id}`);
			return response.data;
	}
);


export const ReportsSlice = createSlice({
	name: "devices",
	initialState: {
		drillDownDeviceData: undefined,
		scheduled: undefined,
		tenants: undefined,
		siteName: undefined,
		deviceDetail: {
			deviceName: undefined,
			serviceName: undefined,
			deviceData: undefined
		}
	},
	reducers: {
		setDrillDownDevices: (state, action) => {
			state.drillDownDeviceData = action.payload;
		},
		setTenantsList: (state, action) => {
			state.tenants = action.payload;
		},

		setSitename: (state, action) => {
			state.siteName = action.payload;
		},

		setDeviceDetails: (state, action) => {
			
			state.deviceDetail = {
				...state.deviceDetail,
				deviceName: action.payload.deviceName,
				serviceName: action.payload.serviceName,
				deviceData: action.payload.deviceData,
			};
		},
			},
	extraReducers: (builder) => {
		builder.addCase(fetchScheduledDevices.fulfilled, (state, action) => {
			state.scheduled = action.payload;
		});
		builder.addCase(fetchUserTenantsData.fulfilled, (state, action) => {
			let data = action.payload;
			state.tenants = data;
		});
	},
});

export const { setDrillDownDevices, setTenantsList, setSitename, setDeviceDetails } = ReportsSlice.actions;
export default ReportsSlice.reducer;
