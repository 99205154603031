import { Box, FormGroup } from '@mui/material'
import ButtonLayout from 'components/common/Button'
import Header from 'components/common/modal/Header'
import React, { useCallback, useState } from 'react'

const AddCommentPopup = ({ text, onClose, isLoading, onhandleSubmitOfComments, placeholder, identifier, }) => {

	const [inputValue, setInputValue] = useState()
	const handleInputChange = useCallback((val) => {
		setInputValue(val)
	}, [])

	return (
		<div className="create__comment__popup">
			<Header
				text={text}
				close={onClose}
			/>
			<FormGroup className="form_group_wrap">
				<textarea
					rows={3}
					className="input_field textarea"
					onChange={(e) => handleInputChange(e?.target?.value)}
					value={inputValue}
					placeholder={placeholder}
				/>
			</FormGroup>

			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width:"100%", padding: "0px 30px 20px 30px" }}>
				<ButtonLayout
					disabled={inputValue?.length === 0 || !inputValue}
					handleClick={(e) => { e?.stopPropagation(); setInputValue("") }}
					buttonStyleType={"outlined"} text={'Clear'}>
				</ButtonLayout>
				<ButtonLayout buttonStyleType="filled"
					disabled={inputValue?.length === 0 || !inputValue}
					isLoading={isLoading}
					handleClick={() => onhandleSubmitOfComments(inputValue, identifier)}
					type="submit" text={"Save"}
				>
				</ButtonLayout>
			</Box>


		</div>

	)
}

export default AddCommentPopup
