import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SpeedDial from "@mui/material/SpeedDial";
import Tooltip from "@mui/material/Tooltip";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { formTypes } from "constant/Formtypes";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useEffect } from "react";
import { useCanAccess } from "services/canACLAccess";

const drawerWidth = 190;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: "52px",
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export default function MiniDrawer({ open, setOpen }) {
	const user = useSelector((state) => state.user);
	const location = useLocation();
	const { pathname } = location;
	const navigate = useNavigate();
	const canAccess = useCanAccess();

	const isActive = (link) => {
		if (link?.includes(pathname)) {
			return true;
		}
		return false;
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (user?.tenant?.name && user?.tenant?.name !== 'Sangoma' && (pathname === "/nf-status/tenants" || pathname === "/nf-status/service" || pathname === "/nf-status/tenants/changelog" || (user.userRole == 'admin' &&  pathname == "/nf-status/user-management"))) {
			navigate("/")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.tenant])

	const changeLocation = (url) => {
		if (pathname !== url) {
			navigate(url, { replace: true });
		} else {
			navigate(url, { replace: true });
			window.location.reload();
		}
	}

	const getClassName = (activeLink) => {
		const activeClassName = isActive(activeLink)
			? "active list_item_cont"
			: "list_item_cont";
		const modeClassName = user?.themePreference === 'dark' ? 'darkMode' : 'lightMode';
		return activeClassName + ' ' + modeClassName
	}

	const menuList = (text) => {
		return (
			<Box
				className={
					getClassName(text?.activeLink)
				}
			>
				<Link
					className={
						isActive(text?.activeLink) ? "active_item flex" : "list_item flex"
					}
					to={`/nf-status/${text.category}`}
					onClick={() => changeLocation(`/nf-status/${text.category}`)}
				>
					<Tooltip title={text.title} placement="right-end">
						<ListItemIcon color={user?.themePreference === 'dark' ? '#fff' :''} className="list_icon">
						{user?.themePreference === 'dark' ? text?.darkModeIcon : text?.icon}
					
						</ListItemIcon>
					</Tooltip>
					<ListItemText primary={text.title} />
				</Link>
			</Box>
		);
	};

	const sortedFormTypes = () => {
		return formTypes.sort((a,b) =>{ return a.orderIndex - b.orderIndex});
	}

	
	return (
		<>
			<Header />
			<Box
				open={open}
				onClick={open ? handleDrawerClose : handleDrawerOpen}
				className={user?.themePreference === 'dark' ? 'speed__dial__darkmode' : ''}
			>
				<SpeedDial
					ariaLabel="open Drawer"
					sx={
						open
							? { position: "absolute", top: 100, left: 170 }
							: { position: "absolute", top: 100, left: 33 }
					}
					className="openDrawer"
					icon={
						open ? (
							<FontAwesomeIcon
								
								icon="fa-solid fa-angle-left"
								style={{ position: "relative", left: "6px" }}
							/>
						) : (
							<FontAwesomeIcon
						
								icon="fa-solid fa-angle-right"
								style={{ position: "relative", left: "6px" }}
							/>
						)
					}
					direction="right"
				/>
			</Box>
			<Drawer
				className={user?.themePreference === 'dark' ? 'sidebar_wrap darkmode' : 'sidebar_wrap'}
				open={open}
				variant="permanent"
				anchor="left"
			>
				<List className="list">
					{sortedFormTypes()?.map((text, index) => {
						if (user?.tenant?.name !== 'Sangoma' && user.userRole == 'user') {
							if (text.resources.length <= 0 || (text?.category !== "tenants" && text?.category !== "service" && text.resources.filter(res => canAccess('read', res)).length)) {
								return (
									<ListItem className="flex-col" key={index} disablePadding>
										{menuList(text)}
									</ListItem>
								);
							}
						}else if (user?.tenant?.name !== 'Sangoma' && user.userRole == 'admin') {
							if (text.resources.length <= 0 || (text?.category !== "tenants" && text?.category !== "service" && text?.category !== "user-management" && text.resources.filter(res => canAccess( 'read', res)).length)) {
								return (
									<ListItem className="flex-col" key={index} disablePadding>
										{menuList(text)}
									</ListItem>
								);
							}
						}else {
							
							if (text.resources.length <= 0 || text.resources.filter(res => canAccess('read', res)).length) {
								return (
									<ListItem className="flex-col" key={index} disablePadding>
										{menuList(text)}
									</ListItem>
								);
							}
						}
						return null;
					})}
	<ListItem className="flex-col" disablePadding>
                                                <Box className={getClassName(["/nf-status/tickets"])}>
                                                        <a className="list_item flex" target="_blank" href="https://help.sangoma.com/" rel="noreferrer">
                                                                <Tooltip title={'Tickets'} placement="right-end">
                                                                        <ListItemIcon color={user?.themePreference === 'dark' ? '#fff' : ''} className="list_icon">
                                                                                {user?.themePreference === 'dark' ? <FontAwesomeIcon className="sideIcon darkMode user_management_icon" icon="fa-solid fa-ticket" /> : <FontAwesomeIcon className="sideIcon user_management_icon" icon="fa-solid fa-ticket" />}

                                                                        </ListItemIcon>
                                                                </Tooltip>
                                                                <ListItemText primary={'Tickets'} />
                                                        </a>
                                                </Box>
                                        </ListItem>

				</List>
			</Drawer>
		</>
	);
}
