import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

// ** Fetch changelogs data
export const fetchEventLogData = createAsyncThunk(
    "eventlogs",
    async (data) => {
        const url = `${base_url}/monitor-devices/event`
        const response = await axios.get(url, {params: {...data}});
        return response.data;
    }
);

export const fetchEventLogDataByTenant = createAsyncThunk(
    "eventlogsByTenant",
    async (data) => {
        const {tenantId, ...rest} = data;
        const url = `${base_url}/monitor-devices/event/byTenantId/${tenantId}`
        const response = await axios.get(url, {params: {...rest}});
        return response.data;
    }
);

export const fetchFlappedSiteNDevices = createAsyncThunk('flappedEventByTenant',
    async (data) => {
        const {tenantId, ...rest} = data;
        const url = `${base_url}/monitor-devices/flappingEvent/byTenantId/${tenantId}`
        const response = await axios.get(url, {params: {...rest}});
        return response.data;
    }
)

export const updateRecord = createAsyncThunk('updateRecord', async (data) => {
    const url = `${base_url}/monitor-devices/flappingEvent/updateRecord`
    const response = await axios.post(url, data);
    return response;
})
