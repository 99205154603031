import { Box } from '@mui/material';
import ButtonLayout from 'components/common/Button';
import CustomQuillEditor from 'components/common/CustomQuillEditor/CustomQuillEditor';
import FormPopup from 'components/common/modal/FormPopup';
import Header from 'components/common/modal/Header';
import React from 'react'
import { removeHtmlTagsBetweenStrings } from './LinkMonitoring';

const NotesPopup = ({ openAddNotesPopup, resetNotes, isEditingNotes, notes, handleNotesChange, setNotes, isNotesLoading, handleNotes }) => {
    
    return (
        <FormPopup open={openAddNotesPopup} size={" "} className={""}>
            <div className="add__notes__wrapper">
                <Header text={isEditingNotes ? "Edit Note" : "Add Note"} close={() => { resetNotes() }} />
                <div className="link__monitoring__quill__editor__wrapper">
                    <CustomQuillEditor notes={notes} handleNotesChange={handleNotesChange} />
                </div>
                <Box sx={{ justifyContent: "space-between" }} className=" buttonWrap" gap={"15px"}>
                    <ButtonLayout
                        disabled={notes && removeHtmlTagsBetweenStrings(notes)?.length === 0 || !notes}
                        handleClick={() => { setNotes() }}
                        buttonStyleType={"outlined"} text={'Clear'}>
                    </ButtonLayout>
                    <ButtonLayout key={notes} buttonStyleType="filled"
                        isLoading={isNotesLoading}
                        handleClick={() => { handleNotes() }}
                        type="submit" text={isEditingNotes ? 'Update Note' : 'Save Note'}>
                    </ButtonLayout>
                </Box>

            </div>
        </FormPopup>
    )
}

export default NotesPopup

