import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

export const fetchEnterprises = createAsyncThunk(
	"appReports/fetchEnterprises",
	async (serverName) => {
		const response = await axios.get(`${base_url}/services/getallenterprise/${serverName}`);
		return response.data;
	}
);

export const fetchUnmappedEnterprises = createAsyncThunk(
	"appReports/fetchUnmappedEnterprises",
	async (serverName) => {
		const response = await axios.get(`${base_url}/services/getallunmappedenterprise/${serverName}`);
		return response.data;
	}
);

export const fetchMerakiOrg = createAsyncThunk(
	"appReports/fetchMerakiOrg",
	async () => {
		const response = await axios.get(`${base_url}/services/getmerakiorg`);
		return response.data;
	}
);

export const fetchUnmappedMerakiOrg = createAsyncThunk(
	"appReports/fetchUnmappedMerakiOrg",
	async () => {
		const response = await axios.get(`${base_url}/services/getunmappedmerakiorg`);
		return response.data;
	}
);

export const fetchCradlePointAccounts = createAsyncThunk(
	"appReports/fetchCradlePointAccounts",
	async () => {
		const response = await axios.get(`${base_url}/services/cradlepoint/accounts`);
		return response.data;
	}
);

export const fetchUnmappedCradlePointAccounts = createAsyncThunk(
	"appReports/fetchUnmappedCradlePointAccounts",
	async () => {
		const response = await axios.get(`${base_url}/services/cradlepoint/accounts/unmapped`);
		return response.data;
	}
);

export const fetchWugDevice = createAsyncThunk(
	"appReports/fetchWugDevice",
	async () => {
		const response = await axios.get(`${base_url}/services/getwugdevice`);
		return response.data;
	}
);

export const fetchUnmappedWugDevice = createAsyncThunk(
	"appReports/fetchUnmappedWugDevice",
	async () => {
		const response = await axios.get(`${base_url}/services/getunmappedwugdevice`);
		return response.data;
	}
);

export const fetchFortinetAdoms = createAsyncThunk(
	"appReports/fetchFortinetAdoms",
	async () => {
		const response = await axios.get(`${base_url}/services/fortinet/adoms`);
		return response.data;
	}
);

export const fetchUnmappedFortinetAdoms = createAsyncThunk(
	"appReports/fetchUnmappedFortinetAdoms",
	async () => {
		const response = await axios.get(`${base_url}/services/fortinet/adoms/unmapped`);
		return response.data;
	}
);

export const fetchVeloServers = createAsyncThunk(
	"appReports/fetchVeloServers",
	async (id) => {
		if (id) {
			const response = await axios.get(`${base_url}/services/getserver/${id}`);
			return response.data;
		}
	}
);

export const ReportsSlice = createSlice({
	name: "services",
	initialState: {},
	reducers: {},
});

export default ReportsSlice.reducer;
