import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "assets/icons/close.svg";
import React from "react";
import { MuiTypography } from "../MuiThemedComponents";
import { useSelector } from "react-redux";

function Header({ text, close, subText }) {
	const user = useSelector(state => state?.user)
	return (
		<Box className="header_wrap justify-between">
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<MuiTypography className="heading">{text}</MuiTypography>
				<MuiTypography className="sub__heading" >{subText}</MuiTypography>
			</Box>

			<Button className="close_btn" onClick={close}>
				<img style={{ filter: (user?.themePreference === 'dark' ? 'invert(1)' : '') }} src={Close} alt="close" />
			</Button>
		</Box>
	);
}

export default Header;
