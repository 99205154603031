import Box from "@mui/material/Box";
import FormLayout from "components/common/FormLayout";
import ProgressLoading from "components/common/ProgressLoading";
import Footer from "components/common/modal/Footer";
import Header from "components/common/modal/Header";
import { formTypes } from "constant/Formtypes";
import { TableTypes } from "constant/TableColTypes";
import {  useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createContactData, updateContactData } from "store/devices";
import { setLoading } from "store/user";

const CreateContact = ({
    handleClose,
    tenantId,
    updateData,
    isUpdateList,
    editData

}) => {
    const [parameters, setParameters] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [nameError, setNameError] = useState([]);
	const [isError, setIsError] = useState(false);
    const [markError, setMarkError] = useState(false);

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const isEditData = editData ? Object?.keys(editData)?.length: 0


    const createParameters = async () => {
        let fieldList = TableTypes?.find((obj) => obj?.category === "Contact_Details");
        let dropdownOption = fieldList?.parameters;
        setIsLoading(false);
        setParameters(dropdownOption);
    };
    useEffect(() => {

        if (editData) {
			if (Object?.keys(editData)?.length) {
				let data = {
					...editData,
				};
				setFormValues(data);
			}
        }


        if (formTypes?.length ) {
            setIsLoading(true);
            createParameters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formTypes, user?.tenant]);
    
    useEffect(() => {
       
            createParameters();
       
    }, []);

    const closeDevice = async () => {
        handleClose();
    };
    
    const validateInput = async (e, id, regex, errorMessage) => {
        if (regex.test(e.target.value)) {
            setNameError("");
            setIsError(false);
            await setFormValues((prevValue) => ({
                ...prevValue,
                [id]: e.target.value,
            }));
        } else {
            setNameError((prevValue) => ({
                ...prevValue,
                msg: errorMessage,
                id: id,
            }));
            setIsError(true);
        }
    };

    const handleInputChange = async (e, id) => {
        if (id === "name" || id === "title") {
            const nameRegex = /^[a-zA-Z0-9-_ ]*$/;
            const nameErrorMessage = "Only alphanumeric characters, hyphen, underscore, and spaces are allowed.";
            await validateInput(e, id, nameRegex, nameErrorMessage);
        }else if(id === "phone"){
            const mobileNumberPattern = /^[0-9]{10}$/;
            const nameErrorMessage = "Invalid mobile number";
            setFormValues((prevValue) => ({
                ...prevValue,
                [id]: e.target.value,
            }));
            await validateInput(e, id, mobileNumberPattern, nameErrorMessage);
        }else if (id === "email") {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const emailErrorMessage = "Invalid email address";
            setFormValues((prevValue) => ({
              ...prevValue,
              [id]: e.target.value,
            }));
            await validateInput(e, id, emailPattern, emailErrorMessage);
        }else{
            setFormValues((prevValue) => ({
                ...prevValue,
                [id]: e.target.value,
            }));
        }
        
    };


    const handleOnfocus = (e, id) => {
        setFormValues((prevValue) => ({ ...prevValue, [id]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(setLoading(true));
        var res = undefined;
        if (Object?.keys(formValues)?.length) {
            const req = {
                name: formValues?.name,
                title: formValues?.title,
                phone: formValues?.phone,
                email: formValues?.email,
                address: formValues?.address,
                comments: formValues?.comments,
                custom_fields: {
                    contact_tenant_id: tenantId.toString()
                }
            }
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            let isEmail = emailPattern.test(req.email);
            if (formValues?.id && req?.name && req?.title && req?.custom_fields?.contact_tenant_id && isEmail ) {
                res = await dispatch(updateContactData({id:formValues?.id, req:req}));
            }else if(req?.name && req?.title && isEmail && req?.custom_fields?.contact_tenant_id ){
                res = await dispatch(createContactData(req));
            }else {
                await dispatch(setLoading(false))
                toast.error(`${!isEmail?"Please fill valid email":"Please fill all the required details"}`);
                setMarkError(true);
                setTimeout(() => {
                    setMarkError(false);
                }, 5000);
            }
            if (res?.payload) {
                await dispatch(setLoading(false));
                toast.success(`Contact ${formValues?.id ? "updated" : "created"} successfully`);
                handleClose();
                if (isUpdateList) {
                    await updateData();
                }
            }
        } else {
            await dispatch(setLoading(false))
            toast.error("Please fill all the required details");
            setMarkError(true);
            setTimeout(() => {
                setMarkError(false);
            }, 5000);
        }
        await dispatch(setLoading(false))
    }


    return (
        <div className="create__site__popup__wrap">
            <Header
            text={ isEditData ? "Update Contact" : "Create Contact"}
                close={closeDevice}
            />{isLoading ? <ProgressLoading /> :
                <Box className="formWrapper site_formWrap">
                    <FormLayout
                        parameters={parameters}
                        formValues={formValues}
                        handleInputChange={handleInputChange}
                        handleOnfocus={handleOnfocus}
                        handleClose={handleClose}
                        nameErrorMsg={nameError}
                        isError={isError}
                        markError={markError}
                    />
                </Box>
            }
            <Footer isLoading={user?.isLoading} text={"Save"} submit={handleSubmit} />

        </div>
    );
};

export default CreateContact;
