// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flapped_site_name_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.flapped_logs_alert{
    font-size: 13px;
    font-weight: 600;
    color: #e73c3cdb
    ;
}

.flapped_dot {
    padding: 3px 5px;
    font-size: 11px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-left: 5px;
    background-color: #ffaa71;
    border-radius: 30px;
}

.flapped_check_icon:hover{
    color: #2e7d32;
}

.flapped_check_icon{
    height: 20px;
    width: 20px;
    color: rgb(146 155 147)
}

.flapped_link_heading{
   display: flex;
   justify-content: space-between;
}

.eventLog .rdt_TableHeadRow{
    display: flex !important;
 }`, "",{"version":3,"sources":["webpack://./src/components/pages/eventLogs/flappingLogs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB;IACA;AACJ;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX;AACJ;;AAEA;GACG,aAAa;GACb,8BAA8B;AACjC;;AAEA;IACI,wBAAwB;CAC3B","sourcesContent":[".flapped_site_name_wrap{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.flapped_logs_alert{\n    font-size: 13px;\n    font-weight: 600;\n    color: #e73c3cdb\n    ;\n}\n\n.flapped_dot {\n    padding: 3px 5px;\n    font-size: 11px;\n    color: #fff;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-weight: 600;\n    margin-left: 5px;\n    background-color: #ffaa71;\n    border-radius: 30px;\n}\n\n.flapped_check_icon:hover{\n    color: #2e7d32;\n}\n\n.flapped_check_icon{\n    height: 20px;\n    width: 20px;\n    color: rgb(146 155 147)\n}\n\n.flapped_link_heading{\n   display: flex;\n   justify-content: space-between;\n}\n\n.eventLog .rdt_TableHeadRow{\n    display: flex !important;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
