import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {
    let location = useLocation();

    const pageTitleToRouteMapper = useCallback(() => {
        const pathName = location.pathname;
        const pathArray = pathName?.split('/').filter(i => i);
        if (pathArray && pathArray.length > 0) {
            const nfStatusIndex = pathArray.indexOf('nf-status');
            if (nfStatusIndex !== -1 && nfStatusIndex < pathArray.length - 1) {
                const combinedString = pathArray.slice(nfStatusIndex + 1).join('-');
                return combinedString.charAt(0).toUpperCase() + combinedString.slice(1);
            } else {
                return document.title;
            }
        }

    }, [location.pathname]);

    React.useEffect(() => {
        // Google Analytics
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: pageTitleToRouteMapper() });
    }, [location]);

    return <></>;
};
export default RouteChangeTracker;